//
// EDITOR

.slide-mediaContainer--image{
	background: none;
	position: relative;
	// background: $tempr;
}

.slide-mediaContainer--gif{
	background: none;
	position: relative;
}


.slide-media--imageCover,
.slide-media--gifCover{
	overflow: hidden;
	position: absolute;
	right: 0px;
	top: 0px;
}

.slide-media--gifCover{
	cursor: pointer; // replace GIF on click
}

.slide-media--imageCover--fill,
.slide-media--gifCover--fill{
	border-radius: 12px;
}

.slide-media--imageCover--fill .slide-media--imageContainer,
.slide-media--gifCover--fill .slide-media--gifContainer{
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.slide-media--imageCover--fit .slide-media--imageContainer,
.slide-media--gifCover--fit .slide-media--gifContainer{
	border-radius: 2px;
}

// .slide-media--imageCover--placeholder .slide-media--imageContainer{
// 	background: $black;
// }

// .slide-media--imageCover--placeholder .slide-media--imageContainer img{
// 	opacity: 0.92;
// }

.slide-media--imageCover--placeholder{
	pointer-events: none !important;
	cursor: default !important;
}

.slide-media--imageCover--placeholder .slide-media--imageContainer{
	border-radius: 2px;
}

//


.slide-media--imageContainer,
.slide-media--gifContainer{
	overflow: hidden;
	position: absolute;
	top: 0px;
	left: 0px;
}

.slide--editor .slide-media--imageContainer{
	cursor: pointer; // edit in editor, zoom in zoomable
}

img.slide-media--image,
.slide-media--gif,
.slide-media--gif *{	
  user-select: none;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 10;
  // pointer-events: none;
  // opacity: 0;
}

// placeholder
.editor .slide--static img.slide-media--image{
	background: #EAF1FE; //placeholder image color
}

.editor .slide--static .slide-media--gif{
	background: #F6F6F6; //placeholder gif color
}

//
// PLACEHOLDER

.slide-media--imageContainer--placeholderTestRealImage{
	opacity: 0.5;
}


img.slide-media--image--placeholder{
	width: 100%;
	height: 100%;
	object-fit: contain;
	cursor: default;
}

.slide-media--imageCover--placeholder{
	z-index: 20; // appear above real image
	cursor: default;
}









//
//

.slide-media--imageContainer-fallBack,
.slide-media--gifContainer-fallBack{	
	position: absolute;
	top: 5px;
	left: 5px;
	right: 5px;
	bottom: 5px;
	background: $gray-15;
	border-radius: 20px;

	display: flex;
	align-items: center;
	justify-content: center;
}

// TOP RIGHT BUTTON
// Expand Image (Static) or Remove Image (Editor)

.slide-mediaContainer--image-topRightBtnContainer,
.slide-mediaContainer--gif-topRightBtnContainer{
	position: absolute;
	top: 0px;
	right: 0px;
	width: 40px;
	height: 40px;

	transform-origin: top right;

	z-index: 200;

	cursor: pointer; 
	// background: $tempr;
}


.slide-mediaContainer--image-topRightBtnContainer--placeholder{
	cursor: default;
}


.slide--static .slide-mediaContainer--image-topRightBtnContainer,
.slide--static .slide-mediaContainer--gif-topRightBtnContainer{
	cursor: pointer; // zoom image
}

button.slide-mediaContainer--image-topRightBtn,
button.slide-mediaContainer--gif-topRightBtn{
	position: absolute;
	top: 9px;
	right: 9px;

	transform-origin: center center;

	width: 28px;
	height: 28px;

	background: none;
	border: none;
	outline: none;
	box-shadow: none;

	background: rgba(0,0,20,0.9);

	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}

// spinner rotate



.slide-mediaContainer--image-topRightBtn svg.editorIcon,
.slide-mediaContainer--gif-topRightBtn svg.editorIcon{	
	width: 14px;
	height: 14px;
	fill: $white;
}

button.slide-mediaContainer--image-topRightBtn,
button.slide-mediaContainer--gif-topRightBtn{
	transition: opacity 50ms linear;
}


.slide .slide-mediaContainer button.slide-mediaContainer--image-topRightBtn,
.slide .slide-mediaContainer button.slide-mediaContainer--gif-topRightBtn{
	opacity: 0;
	// opacity: 0.5 !important;
}

.slide:hover .slide-mediaContainer button.slide-mediaContainer--image-topRightBtn,
.slide:hover .slide-mediaContainer button.slide-mediaContainer--gif-topRightBtn{
	opacity: 0.15;
}

.slide:hover .slide-mediaContainer:hover button.slide-mediaContainer--image-topRightBtn,
.slide:hover .slide-mediaContainer:hover button.slide-mediaContainer--gif-topRightBtn{
	opacity: 0.5;
}

.slide:hover .slide-mediaContainer:hover .slide-mediaContainer--image-topRightBtnContainer:hover button.slide-mediaContainer--image-topRightBtn,
.slide:hover .slide-mediaContainer:hover .slide-mediaContainer--gif-topRightBtnContainer:hover button.slide-mediaContainer--gif-topRightBtn{
	opacity: 0.8;
}

.slide:hover .slide-mediaContainer:hover .slide-mediaContainer--image-topRightBtnContainer:hover button.slide-mediaContainer--image-topRightBtn:hover,
.slide:hover .slide-mediaContainer:hover .slide-mediaContainer--gif-topRightBtnContainer:hover button.slide-mediaContainer--gif-topRightBtn:hover{
	opacity: 1;
}

.slide:hover .slide-mediaContainer:hover .slide-mediaContainer--image-topRightBtnContainer:hover button.slide-mediaContainer--image-topRightBtn.slide-mediaContainer--image-topRightBtn--removeImage:hover,
.slide:hover .slide-mediaContainer:hover .slide-mediaContainer--gif-topRightBtnContainer:hover button.slide-mediaContainer--gif-topRightBtn--removeImage:hover{
	background: $red;
}

// TOP LEFT 
// Placeholder for GIF and Image, also Badge for GIPHY
.slide-mediaContainer--image-topLeftContainer,
.slide-mediaContainer--gif-topLeftContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	// width: 100px;
	// height: 100px;

	transform-origin: top left;

	z-index: 200;

	cursor: default; 
	// background: $tempr;
}

.slide-mediaContainer--gif-topLeftContainer{
	cursor: pointer; 	

	display: flex;
}


// PLACEHOLDER SPINNER
.slide-mediaContainer--image-topLeftContainer-spinnerContainer,
.slide-mediaContainer--gif-topLeftContainer-spinnerContainer{

	position: absolute;
	top: 9px;
	left: 9px;

	transform-origin: top left;

	width: 28px;
	height: 28px;
		
	background: none;
	border: none;
	outline: none;
	box-shadow: none;

	background: rgba(0,0,20,0.9);

	border-radius: 5px;

	position: relative;
	z-index: 10;

	display: flex;
	align-items: center;
	justify-content: center;

	pointer-events: none;

}

@keyframes loadingImageSpinnerRotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}

.slide-mediaContainer--image-topLeftContainer-spinner,
.slide-mediaContainer--gif-topLeftContainer-spinner{
	height: 14px;
	width: 14px;
  
  border-radius: 50%;

  border: 2px rgba(0,0,20,0.9) solid;
  border-top: 2px rgba(255,255,255,1) solid;

  opacity: 1;

  animation: loadingImageSpinnerRotate 0.6s infinite linear;
}

.slide .slide-mediaContainer .slide-mediaContainer--image-topLeftContainer-spinnerContainer,
.slide .slide-mediaContainer .slide-mediaContainer--gif-topLeftContainer-spinnerContainer{
	opacity: 0;
	// opacity: 0.5 !important;
}

.slide:hover .slide-mediaContainer .slide-mediaContainer--image-topLeftContainer-spinnerContainer,
.slide:hover .slide-mediaContainer .slide-mediaContainer--gif-topLeftContainer-spinnerContainer{
	opacity: 0.15;
}

.slide:hover .slide-mediaContainer:hover .slide-mediaContainer--image-topLeftContainer-spinnerContainer,
.slide:hover .slide-mediaContainer:hover .slide-mediaContainer--gif-topLeftContainer-spinnerContainer{
	opacity: 0.5;
}



// GIPHY ATTRIBUTION
// SHARED WITH GIF CHOICES

a.slide-mediaContainer--gif-giphyUserLink{
	position: relative;

	margin-top: 9px;
	margin-left: 9px;

	// background: $tempr;

	display: flex;

	padding-left: 6px;
	padding-right: 26px;
	padding-top: 6px;
	padding-bottom: 6px;

	background: rgba(0,0,20,0.9);

	border-radius: 5px;

	cursor: pointer;

	user-select: none !important;

	min-width: 10px;

	flex-grow: 0;
	flex-shrink: 1;

	max-width: 100%;
}

.slide-choice-mediaContainer--gif
a.slide-mediaContainer--gif-giphyUserLink{
	margin-top: 6px;
	margin-left: 6px;
}


.slide-mediaContainer--gif-giphyUserLink-profilePicContainer{
	
	flex-grow: 0;
	flex-shrink: 0;

	height: 32px;
	width: 32px;

	border-radius: 3px;
	overflow: hidden;

	background: none;

	margin-right: 10px;

	position: relative;
}

.slide-mediaContainer--gif-giphyUserLink-profilePic-img{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	object-fit: cover;

}

.slide-mediaContainer--gif-giphyUserLink-userName{
	flex-grow: 1;
	flex-shrink: 1;

	min-width: 10px;

	color: $white;
}

.slide-mediaContainer--gif-giphyUserLink-userName-userName{
	font-size: 14px;
	font-weight: $lato-heavy;
	letter-spacing: 0.015em;
	margin-bottom: 2px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slide-mediaContainer--gif-giphyUserLink-userName-viewOnGiphy{
	font-size: 12.5px;
	font-weight: $lato-bold;
	opacity: 0.7;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slide .slide-mediaContainer a.slide-mediaContainer--gif-giphyUserLink{
	opacity: 0;
}

.slide:hover .slide-mediaContainer a.slide-mediaContainer--gif-giphyUserLink{
	opacity: 0;
}

.slide:hover .slide-mediaContainer:hover a.slide-mediaContainer--gif-giphyUserLink{
	opacity: 0.7;
}

.slide:hover .slide-mediaContainer:hover .slide-mediaContainer--gif-topLeftContainer:hover a.slide-mediaContainer--gif-giphyUserLink{
	opacity: 0.9;
}

.slide:hover .slide-mediaContainer:hover .slide-mediaContainer--gif-topLeftContainer:hover a.slide-mediaContainer--gif-giphyUserLink:hover{
	opacity: 1;
}



//
//
//
.slide-mediaContainer--image-fillTypeBtnContainer,
.slide-mediaContainer--gif-fillTypeBtnContainer{
	width: 120px;
	height: 40px;

	position: absolute;	

	margin: auto;
	left: 0;
	right: 0;

	transform-origin: center top;

	display: flex;


	z-index: 20;
}

button.slide-mediaContainer--image-fillTypeBtn,
button.slide-mediaContainer--gif-fillTypeBtn{

	width: 100%;

	// margin-top: 10px;
	height: 28px;

	background: none;
	border: none;
	outline: none;
	box-shadow: none;

	background: rgba(0,0,20,0.8);

	border-radius: 6px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	color: $white;
	font-size: 13.5px;
	font-weight: $lato-semibold;
	letter-spacing: 0.01em;

	box-sizing: border-box;

	user-select: none;

}


button.slide-mediaContainer--image-fillTypeBtn--active,
button.slide-mediaContainer--gif-fillTypeBtn--active{
	background: $blue;
}

button.slide-mediaContainer--image-fillTypeBtn--inactive,
button.slide-mediaContainer--gif-fillTypeBtn--inactive{
	background: rgb(0,0,0);
}


// button.slide-mediaContainer--image-fillTypeBtn:first-child{
// 	border-top-right-radius: 0px;
// 	border-bottom-right-radius: 0px;
// }

// button.slide-mediaContainer--image-fillTypeBtn:last-child{
// 	border-top-left-radius: 0px;
// 	border-bottom-left-radius: 0px;
// }



button.slide-mediaContainer--image-fillTypeBtn svg,
button.slide-mediaContainer--gif-fillTypeBtn svg{
	height: 11px;
	width: 11px;

	position: relative;
	top: 0px;

	fill: $white;
	opacity: 0.9;

	margin-right: 5px;
	margin-left: -6px;
}

button.slide-mediaContainer--image-fillTypeBtn svg #B,
button.slide-mediaContainer--image-fillTypeBtn svg #C,
button.slide-mediaContainer--gif-fillTypeBtn svg #B,
button.slide-mediaContainer--gif-fillTypeBtn svg #C,{
	opacity: 0.7;
}

.slide-mediaContainer--image button.slide-mediaContainer--image-fillTypeBtn,
.slide-mediaContainer--gif button.slide-mediaContainer--gif-fillTypeBtn{
	opacity: 0;
}

.slide:hover .slide-mediaContainer button.slide-mediaContainer--image-fillTypeBtn,
.slide:hover .slide-mediaContainer button.slide-mediaContainer--gif-fillTypeBtn{
	opacity: 0.15;
}

.slide:hover .slide-mediaContainer--image:hover button.slide-mediaContainer--image-fillTypeBtn--active,
.slide:hover .slide-mediaContainer--gif:hover button.slide-mediaContainer--gif-fillTypeBtn--active{
	opacity: 0.9;
}

.slide:hover  .slide-mediaContainer--image:hover button.slide-mediaContainer--image-fillTypeBtn--inactive,
.slide:hover .slide-mediaContainer--gif:hover button.slide-mediaContainer--gif-fillTypeBtn--inactive{
	opacity: 0.6;
}

.slide:hover .slide-mediaContainer--image:hover button.slide-mediaContainer--image-fillTypeBtn:hover,
.slide:hover .slide-mediaContainer--gif:hover button.slide-mediaContainer--gif-fillTypeBtn:hover{
	opacity: 1;
}






//
// EDIT IMAGE BUTTON
.slide-mediaContainer--image-editImageBtnContainer,
.slide-mediaContainer--gif-editGifBtnContainer{
	width: 140px;
	height: 40px;

	position: absolute;	

	margin: auto;
	left: 0;
	right: 0;

	transform-origin: center bottom;

	z-index: 20;

}

button.slide-mediaContainer--image-editImage{
	position: absolute;
	
	width: 100%;

	top: 0px;
	height: 32px;

	background: none;
	border: none;
	outline: none;
	box-shadow: none;

	background: rgba(0,0,20,0.8);

	border-radius: 6px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	color: $white;
	font-size: 14px;
	font-weight: $lato-bold;
	letter-spacing: 0.01em;

	box-sizing: border-box;
	padding-bottom: 2px;

}

button.slide-mediaContainer--image-editImage svg{
	height: 14px;
	width: 14px;

	position: relative;
	top: 0px;

	fill: $white;
	opacity: 0.9;

	margin-right: 6px;
	margin-left: -2px;
}

.slide-mediaContainer--image
button.slide-mediaContainer--image-editImage{
	opacity: 0;
}

.slide-mediaContainer--image:hover
button.slide-mediaContainer--image-editImage{
	opacity: 0.7;
}


.slide-mediaContainer--image:hover
button.slide-mediaContainer--image-editImage:hover{
	opacity: 1;
}
