.nowPlaying-countdownTimerContainer{
	
	position: fixed;

	width: 120px;
	height: 70px;

	top: 0px;
	//left: calc(50vw - (300px/2));

	// background: $tempr;

	right: 60px;

	z-index: 9999;

	display: flex;
	align-items: center;
	justify-content: center;
}

.nowPlaying-countdownTimer{
	background: $tempr;
	width: 80px;
	height: 32px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 18px;
	font-weight: $lato-bold;
	color: $white;

	// border: 1px solid $blue-dark;
	border-radius: 5px;
	// background: $blue;

	// background: rgba(0,0,10, 0.9);
	// border: 1px solid rgb(0,0,0);
	// box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.12);

	transform-origin: 50% 50%;
}

.nowPlaying-countdownTimerContainer--selectedChoice
.nowPlaying-countdownTimer{
	transition: opacity 100ms linear;
	background: rgba(0,0,20,0.2);
	// border: 1px solid 
	border: 1px solid $invisible;
	color: rgba(255,255,255,0.5);
}


.nowPlaying-countdownTimer-minutes--zeroMinutes{
	opacity: 0.5;
}


.nowPlaying-countdownTimer--classColor--blue{
	background: $classColor-blue;
}

.nowPlaying-countdownTimer--classColor--purple{
	background: $classColor-purple;
}

.nowPlaying-countdownTimer--classColor--red{
	background: $classColor-red;
}

.nowPlaying-countdownTimer--classColor--orange{
	background: $classColor-orange;
}

.nowPlaying-countdownTimer--classColor--green{
	background: $classColor-green;
}

.nowPlaying-countdownTimer--classColor--sky{
	background: $classColor-sky;
}

.nowPlaying-countdownTimer--classColor--pink{
	background: $classColor-pink;
}

.nowPlaying-countdownTimer--classColor--teal{
	background: $classColor-teal;
}

.nowPlaying-countdownTimer--classColor--grey{
	background: $classColor-grey;
}