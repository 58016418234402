.nowPlaying-topPanel-studentNameBlobContainer{
  position: fixed;
  top: 32px;
  left: 0px;
  z-index: 9999;
  height: 32px;

  padding-top: 5px;
  padding-left: 12px;
  padding-right: 12px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.nowPlaying-topPanel-studentNameBlob{
  display: flex;
  align-items: center;
  justify-content: center;

  height: 22px;
  min-width: 24px;

  border-radius: 4px;

  cursor: default;
  user-select: none;
}

.nowPlaying-topPanel-studentNameBlob-label{
  opacity: 1;
  width: auto;
  overflow: hidden;
  padding-left: 6px;
  padding-right: 6px;

  font-size: 13.5px;
  font-weight: $lato-medium;
  color: $gray-6;
  letter-spacing: 0.02em;

  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nowPlaying-topPanel-studentNameBlobContainer--notScanning{
  opacity: 1;
  transition: opacity 200ms linear;
  transition-delay: 100ms;
}

.nowPlaying-topPanel-studentNameBlobContainer--scanning{
  opacity: 0;
  transition: opacity 200ms linear;
}
