.nowPlaying-toggleScanFake{
	// display: none;
	left: 0px;
	bottom: 0px;
	height: 150px;
	width: 150px;
	
	position: fixed;
	background: rgba(250,250,255,0);
	// background: $tempr;
	z-index: 9999;
}

.nowPlaying-resetToggle{

}

.nowPlaying-toggleCorrectFake{	
	// display: none;
	right: 0px;
	bottom: 0px;
	height: 150px;
	width: 150px;	
	position: fixed;
	background: rgba(250,250,255,0);
	// background: $tempg;
	z-index: 9999;
}


.nowPlaying-toggleSwitchQuestionFake{
	// display: none;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 200px;
	height: 200px;
	background: $invisible;
	z-index: 9999;
}
