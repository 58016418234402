.slide-mediaContainer--sound{
	background: none;
	position: relative;
	text-align: left;
}

.slide-media--sound{
	transform-origin: top left;
	position: absolute;
	top: 0px;
	left: 0px;
}

.slide-media--sound{
	// width provided by JS for scaling

	box-sizing: border-box;
	padding: 10px;

	background: $white;
	border: 1px solid rgb(239,238,241);
	box-shadow: 0px 2px 2px 0px rgba(0,0,20,0.06);

	border-radius: 9px;

	cursor: default;
}


.slide-media--sound--widePlayer{
	//height: 210px;
}

.slide-media--sound--widePlayer.slide-media--sound--zoomablePlayer
.slide-media--sound-controls{
	margin-top: 15px;
}


.slide-media--sound--zoomablePlayer{
	padding-top: 40px;
}


.slide-media--sound--fullyStatic{
	pointer-events: none;
	padding-bottom: 24px;
}


.slide-media--sound--widePlayer.slide-media--sound--fullyStatic{
	height: 170px;
}


.slide--template--bodyCenterMediaCenter
.slide-media--sound{
	top: unset;
	bottom: 0px;

	margin: auto;
	transform-origin: bottom left;
}


//
// TOP LEFT AND RIGHT
//

$bgColor-topBtn-mediaSound: rgb(244,244,250);

.slide-media--sound-topLeft{
	position: absolute;
	top: 8px;
	left: 8px;
}

.slide-media--sound-topRight{
	position: absolute;
	top: 8px;
	right: 8px;

	display: flex;
	align-items: center;
}

// EDIT BTN
button.slide-media--sound-topBtn{
	height: 24px;
	
	font-size: 14px;
	font-weight: $lato-bold;
	color: $textDark;
	color: $textMid;

	background: $bgColor-topBtn-mediaSound;
	border: none;
	outline: none;
	box-shadow: none;

	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
	user-select: none;
}

button.slide-media--sound-topBtn--edit{
	padding-left: 10px;
	padding-right: 10px;
}

button.slide-media--sound-topBtn--waveform{
	width: 30px;
}

.slide-media--sound--widePlayer
button.slide-media--sound-topBtn--waveform{
	width: auto;
	padding-left: 10px;
	padding-right: 10px;
}

.slide-media--sound--widePlayer
button.slide-media--sound-topBtn--waveform svg{
	margin-right: 5px;
}

button.slide-media--sound-topBtn--close{
	width: 24px;
	margin-left: 4px;
}

button.slide-media--sound-topBtn--zoom{
	width: 48px;
}


button.slide-media--sound-topBtn svg{
	fill: $gray-6;
}

button.slide-media--sound-topBtn--waveform svg{
	width: 16px;
	height: 16px;
}

button.slide-media--sound-topBtn--close svg{
	width: 12px;
	height: 12px;
}

button.slide-media--sound-topBtn--zoom svg{
	width: 14px;
	height: 14px;
}


// button.slide-media--sound-waveformBtn--active svg{
// 	fill: $gray-8;
// }

// button.slide-media--sound-waveformBtn--inactive svg{
// 	fill: $gray-12;
// }


.slide .slide-mediaContainer
button.slide-media--sound-topBtn{
	opacity: 0;
}

.slide:hover .slide-mediaContainer
button.slide-media--sound-topBtn{
	opacity: 0.15;
}

.slide .slide-mediaContainer:hover
button.slide-media--sound-topBtn{
	opacity: 0.5;
}


.slide .slide-mediaContainer
button.slide-media--sound-topBtn:hover{
	opacity: 1;
	background: transparentize($blue, 0.9);
	color: $blue;
}

.slide .slide-mediaContainer
button.slide-media--sound-topBtn:hover svg{
	fill: $blue;
}

.slide .slide-mediaContainer
button.slide-media--sound-topBtn--close:hover{
	background: transparentize($red, 0.8);
}

.slide .slide-mediaContainer
button.slide-media--sound-topBtn--close:hover svg{
	fill: $red;
}




// INFO AND TIMESTAMPS
.slide-media-sound--infoAndTimestampContainer{
	//margin-top: 26px; // take out if center
	height: 38px;

	width: calc(100% - 110px);
	margin-left: 55px;

	box-sizing: border-box;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-bottom: 8px;
	margin-top: -3px; // refactor
}



.slide-media--sound--widePlayer
.slide-media-sound--infoAndTimestampContainer{
	margin-bottom: 0px;
	width: calc(100% - 300px);
	margin-left: 150px;
}

.slide-media-sound--infoAndTimestamp{
	width: 100%;
}

.slide-media-sound--infoAndTimestamp-title{
	width: 100%;
	box-sizing: border-box;
	text-align: center;

	font-size: 14.5px;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.02em;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 7px;
  padding-right: 7px;

  border-radius: 4px;

  cursor: pointer;

  margin-bottom: -2px;
  user-select: none;
}

.slide-media-sound--infoAndTimestamp-title:hover{
	background: transparentize($bgColor-topBtn-mediaSound, 0.45);
	// color: $blue;
}

.slide-media--sound--staticPlayer
.slide-media-sound--infoAndTimestamp-title{
	pointer-events: none;
}



.slide-media-sound--infoAndTimestamp-timestamp{
	width: 100%;
	text-align: center;

	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textLight;
	user-select: none;
}

//

.slide-media-sound--infoAndTimestamp-playingTimestamp{
	font-size: 16.5px;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.03em;
	text-align: center;
	margin-top: -4px; // refactor
	user-select: none;
}





//
// SEEKBAR AND WAVEFORM SECTION
// 

.slide-media--sound-seekBarContainer{
	//height: $height-media-sound-seekBar;
	height: calc(100% - 108px);
	width: 100%;
	position: relative;
	box-sizing: border-box;

	padding-left: 8px;
	padding-right: 8px;

	// background: $tempg;
}

.slide-media--sound--widePlayer
.slide-media--sound-seekBarContainer{
	height: calc(100% - 100px);
}



.slide-media--sound-seekBarContainer .rc-slider{
	height: 100%;
	cursor: pointer;

	// background: $tempr;
}

.slide-media--sound-seekBarContainer .rc-slider-rail{

}

.slide-media--sound-seekBarContainer
.rc-slider-handle{
	width: 15px;
	height: 15px;
	margin-top: -6px;
	// border-radius: 50%;
	background: $blue;
	border: 2px solid white;
	box-shadow: 0px 0px 0px 2px rgba(0,0,10,0.04);
	cursor: pointer;

	top: 0px;
	bottom: 0px;
	margin: auto;

	// position: absolute;
	// top: 3px;
	// left: 25%;
}

.slide-media--sound-seekBarContainer .rc-slider-handle:hover,
.slide-media--sound-seekBarContainer .rc-slider-handle:focus,
.slide-media--sound-seekBarContainer .rc-slider-handle:active{
	border: 1px solid white;
	box-shadow: 0px 0px 0px 2px rgba(0,0,10,0.04);
	cursor: pointer;
}


.slide-media--sound-seekBarContainer
.rc-slider-track{
	background: $blue;
	height: 3px;
	top: 0px;
	bottom: 0px;
	margin: auto;
}

.slide-media--sound-seekBarContainer
.rc-slider-rail{	
	background: rgba(0,0,20,0.2);
	height: 3px;
	top: 0px;
	bottom: 0px;
	margin: auto;
}

.slide-media--sound-player-seekBar-tooltip{
	display: none;
}

.slide-media--sound-seekBarContainer-waveformImageContainer{
	position: absolute;
	bottom: 0px;
	top: 0px;
	left: 8px;
	right: 8px;
	z-index: -1;
	pointer-events: none;
}

.slide-media--sound-seekBarContainer-waveformImageContainer img{
	width: 100%;
	height: 100%;
	object-fit: fill;

	opacity: 0.05;
}

.slide-media--sound-seekBarContainer--notPlaying
.slide-media--sound-seekBarContainer-waveformImageContainer img{
	opacity: 0.08;
	transition: opacity 100ms linear;
}

.slide-media--sound-seekBarContainer--isPlaying
.slide-media--sound-seekBarContainer-waveformImageContainer img{
	opacity: 0.04;
	transition: opacity 250ms linear;
}

// STATIC TIMESTAMPS

.slide-media--sound-seekBarContainer-staticTimestamp{
	position: absolute;
	//top: 52px;
	top: 64px;

	font-size: 15px;
	font-weight: $lato-bold;
	letter-spacing: 0.03em;
	color: $textBlackDark;
}

.slide-media--sound-seekBarContainer-staticTimestamp--current{
	left: 8px;
}

.slide-media--sound-seekBarContainer-staticTimestamp--current--active{
	opacity: 1;
	transition: opacity 250ms linear;
}

.slide-media--sound-seekBarContainer-staticTimestamp--current--inactive{
	opacity: 0.25;
	transition: opacity 100ms linear;
}

.slide-media--sound-seekBarContainer-staticTimestamp--duration{
	right: 8px;
	opacity: 0.5;
}

//
// SOUND CONTROLS SECTION
//
.slide-media--sound-controls{
	// width: 100%;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	bottom: 14px;
	left: 0px;
	right: 0px;
}

.slide-media--sound-controls-playPauseBtn{
	width: 120px;
	height: 60px;

	border: none;
	background: none;
	box-shadow: none;

	border-radius: 5px;

	background: transparentize($blue, 0.95);
	cursor: pointer;
}

.slide-media--sound-controls-playPauseBtn svg{
	height: 26px;
	width: 26px;

	fill: $blue;
}

.slide-media--sound-controls-playPauseBtn svg.editorIcon-playbackPlay{
	position: relative;
	right: -2px;
}

.slide-media--sound-controls-playPauseBtn:hover svg{
	transform: scale(1.1);
}


button.slide-media--sound-controls-seekJump{
	width: 70px;
	height: 60px;

	background: none;
	border: none;
	box-shadow: none;

	background: none;

	border-radius: 4px;

	cursor: pointer;
}

.slide-media--sound-controls-seekJump svg{
	width: 22px;
	height: 22px;

	fill: $gray-4;
	opacity: 0.5;
}

// .slide-media--sound-controls-seekJump:hover{
// 	background: transparentize($blue, 0.95);
// }

.slide-media--sound-controls-seekJump:hover svg{
	transform: scale(1.1);
	opacity: 1;
}

//
// FULLY STATIC


.slide-media--sound--fullyStatic .slide-media-sound--infoAndTimestampContainer{
	user-select: none;
	position: absolute;
	top: 0px;
	left: 40px;
	right: 40px;
	bottom: 0px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: -136px;
	margin-bottom: 0px;
	margin-left: 0px;

	height: unset;
	width: unset;
}

// Similar to video fully-static approach
.slide-media--sound--fullyStatic .slide-media-sound--infoAndTimestamp-title{
	font-size: 20px;
	font-weight: $lato-bold;
	letter-spacing: 0.04em;
}

.slide-media--sound--fullyStatic .slide-media-sound--infoAndTimestamp-title svg.editorIcon{
	width: 20px;
	height: 20px;
	fill: $blue;
	position: relative;
	top: 3px;
	margin-right: 10px;
}

.slide-media--sound--fullyStatic .slide-media-sound--infoAndTimestamp-timestamp{
	font-size: 18px;
	font-weight: $lato-semibold;
	letter-spacing: 0.03em;
}

.slide-media--sound--fullyStatic
.slide-media--sound-seekBarContainer-waveformImageContainer{
	z-index: 0;
}

.slide-media--sound--fullyStatic
.slide-media--sound-seekBarContainer-waveformImageContainer img{
	opacity: 0.1;
}

.slide-media--sound--fullyStatic 
.slide-media--sound-seekBarContainer{
	height: calc(100% - 140px);
	margin-top: 115px;

}




// // TIMESTAMPS

// .slide-media--sound-top-centerContainer{
// 	// background: $tempb;
// 	min-width: 10px;
// 	flex-grow: 1;
// 	flex-shrink: 1;
// 	height: 100%;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .slide-media--sound-top-timestamp{
// 	font-size: 15px;
// 	font-weight: $lato-semibold;
// 	color: $textDark;
// }

// .slide-media--sound-top-timestamp--initialTotal{
// 	opacity: 0.5;
// }

// .slide-media--sound-top-timestamp--current,
// .slide-media--sound-top-timestamp--total{
// 	width: 40px;
// 	text-align: center;
// 	// background: $tempr;
// }

// .slide-media--sound-top-timestamp--current{
// 	margin-left: -8px;
// }

// .slide-media--sound-top-timestamp--total{
// 	opacity: 0.5;
// }

// .slide-media--sound-top-timestamp--progressDash{

// 	opacity: 0.5;
// }

// .slide-media--sound--static
// .slide-media--sound-top-timestamp{
// 	font-size: 22px;
// 	margin-top: 10px;
// }


// //
// // PLAYER SECTION
// //

// $height-media-sound-player: 76px;

// .slide-media--sound-player{
// 	display: flex;
// 	height: $height-media-sound-player;

// 	align-items: center;
// }

// .slide-media--sound-player-leftContainer,
// .slide-media--sound-player-rightContainer{
// 	width: 90px;
// 	// background: $tempg;
// 	height: 100%;
// 	flex-grow: 0;
// 	flex-shrink: 0;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	box-sizing: border-box;
// }

// // STATIC
// .slide--static .slide-media--sound-player-leftContainer{
// 	width: 60px;
// }

// .slide--static .slide-media--sound-player-rightContainer{
// 	width: 45px;
// }



// // PLAY PAUSE BUTTON

// .slide-media--sound-player-leftContainer{
// 	padding-left: 2px;
// 	padding-right: 14px;
// }

// .slide--static .slide-media--sound-player-leftContainer{
// 	padding-right: 12px;
// 	padding-left: 0px;
// }

// button.slide-media--sound-playPauseBtn{
// 	width: 100%;
// 	height: 40px;

// 	background: transparentize($blue, 0.95);

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	border: none;
// 	box-shadow: none;
// 	padding: 0px;

// 	border-radius: 4px;

// 	cursor: pointer;

// }

// button.slide-media--sound-playPauseBtn svg{
// 	height: 18px;
// 	width: 18px;
// 	fill: $blue;
// }

// // STATIC SLIDE STATIC SOUND

// .slide-media--sound--static 
// .slide-media--sound-player-centerContainer svg{
// 	position: absolute;
// 	margin: auto;
// 	top: 20px;
// 	bottom: 0px;
// 	left: 0px;
// 	right: 0px;
// 	height: 48px;
// 	width: 48px;
// 	fill: $blue;	
// }



// // SECONDARY BUTTONS

// .slide-media--sound-player-rightContainer{
// 	display: flex;
// 	align-items: center;
// 	justify-content: flex-end;

// 	box-sizing: border-box;
// 	padding-left: 8px;
// }

// button.slide-media--sound-secondaryBtn{
// 	background: $gray-14;
// 	border: none;
// 	box-shadow: none;

// 	cursor: pointer;

// 	border-radius: 4px;

// 	height: 34px;
	
// 	width: calc(50% - 6px);

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	padding: 0px;

// }

// .slide--static
// button.slide-media--sound-secondaryBtn{
// 	width: 100%;
// }

// button.slide-media--sound-secondaryBtn:first-child{
// 	margin-right: 4px;
// }

// button.slide-media--sound-secondaryBtn svg{
// 	width: 17px;
// 	height: 17px;
// 	fill: $gray-7;
// }

// button.slide-media--sound-secondaryBtn svg.editorIcon-playbackRestart{
// 	width: 15px;
// 	height: 15px;
// }

