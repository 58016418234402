
//
// RULES LIKE BOLD AND ITALIC AND HIGHLIGHT
//


//maybe refactor
sup {
  vertical-align: super;
  font-size: smaller;
}

.highlight {  
  color: black;
}

.highlightRed {
  background-color: red;
  color: black;
}



//
// BOLD
//

.slide-body b{
  font-weight: $lato-black;
  color: rgb(0,0,0);
}


.slide-choice b{
  font-weight: $lato-heavy;
  color: rgb(0,0,0);
}

// .slide-body blockquote{
//   font-weight: 900;
//   color: rgb(100,0,0);
//   background: #F6F6F6
// }

//
// ITALIC - TO DO
//

.slide-body em,
.slide-choice em{
  font-style: italic;
}

//
// SUPERSCRIPT AND SUBSCRIPT
// these should be able to be handled by default by the browser
.slide-body sub,
.slide-choice sub {
  vertical-align: sub;
  font-size: smaller;
}

.slide-body sup,
.slide-choice sup {
  vertical-align: super;
  font-size: smaller;
}


//
// HIGHTLIGHT
//

.slide-body span.highlight,
.slide-choice span.highlight{
	border-radius: 0.1em;
	padding-left: 0.08em;
	padding-right: 0.08em;
	margin-left: -0.06em;
	margin-right: -0.06em;
}




// Highlight Red

.slide-body span.highlight--1,
.slide-choice span.highlight--1{	
	background: $highlight--1-bgColor;
	color: $highlight--1-textColor;
	// background: rgb(255,191,181);
	// background: #FDE5E6;
	// background: transparentize(#EF3136,0.9);
	// color: #EF3136;
}

.slide-body span.highlight--1 .katexOuterContainer:not(.ProseMirror-selectednode),
.slide-choice span.highlight--1 .katexOuterContainer:not(.ProseMirror-selectednode){
	background: $highlight--1-bgColor;
	color: $highlight--1-textColor;
	box-shadow: 0px 0px 0px 3px $highlight--1-bgColor;
}

.slide-body span.highlight--1 .katexOuterContainer:hover:not(.ProseMirror-selectednode),
.slide-choice span.highlight--1 .katexOuterContainer:hover:not(.ProseMirror-selectednode){	
	background: darken($highlight--1-bgColor, 4%);	
	box-shadow: 0px 0px 0px 3px darken($highlight--1-bgColor, 4%);
}


// Highlight Orange

.slide-body span.highlight--2,
.slide-choice span.highlight--2{		
	background: $highlight--2-bgColor;
	color: $highlight--2-textColor;
	// background: rgb(247,204,98);	
	// background:#FFEEE2;
	// color:#DF6513;
}



.slide-body span.highlight--2 .katexOuterContainer:not(.ProseMirror-selectednode),
.slide-choice span.highlight--2 .katexOuterContainer:not(.ProseMirror-selectednode){
	background: $highlight--2-bgColor;
	color: $highlight--2-textColor;
	box-shadow: 0px 0px 0px 3px $highlight--2-bgColor;
}

.slide-body span.highlight--2 .katexOuterContainer:hover:not(.ProseMirror-selectednode),
.slide-choice span.highlight--2 .katexOuterContainer:hover:not(.ProseMirror-selectednode){	
	background: darken($highlight--2-bgColor, 4%);	
	box-shadow: 0px 0px 0px 3px darken($highlight--2-bgColor, 4%);
}




// Highlight Green

.slide-body span.highlight--3,
.slide-choice span.highlight--3{
	background: $highlight--3-bgColor;
	color: $highlight--3-textColor;
	// background: rgb(181,220,175);
	// background: #D6F4EB;
	// color: #009D6F;
}


.slide-body span.highlight--3 .katexOuterContainer:not(.ProseMirror-selectednode),
.slide-choice span.highlight--3 .katexOuterContainer:not(.ProseMirror-selectednode){
	background: $highlight--3-bgColor;
	color: $highlight--3-textColor;
	box-shadow: 0px 0px 0px 3px $highlight--3-bgColor;
}

.slide-body span.highlight--3 .katexOuterContainer:hover:not(.ProseMirror-selectednode),
.slide-choice span.highlight--3 .katexOuterContainer:hover:not(.ProseMirror-selectednode){	
	background: darken($highlight--3-bgColor, 4%);	
	box-shadow: 0px 0px 0px 3px darken($highlight--3-bgColor, 4%);
}


// Highlight Blue

.slide-body span.highlight--4,
.slide-choice span.highlight--4{	
	background: $highlight--4-bgColor;
	color: $highlight--4-textColor;
	// background: rgb(213,232,250);	
	// background: rgb(235,244,254);
	// color: rgb(14,119,222);
}

.slide-body span.highlight--4 .katexOuterContainer:not(.ProseMirror-selectednode),
.slide-choice span.highlight--4 .katexOuterContainer:not(.ProseMirror-selectednode){
	background: $highlight--4-bgColor;
	color: $highlight--4-textColor;
	box-shadow: 0px 0px 0px 3px $highlight--4-bgColor;
}

.slide-body span.highlight--4 .katexOuterContainer:hover:not(.ProseMirror-selectednode),
.slide-choice span.highlight--4 .katexOuterContainer:hover:not(.ProseMirror-selectednode){	
	background: darken($highlight--4-bgColor, 4%);	
	box-shadow: 0px 0px 0px 3px darken($highlight--4-bgColor, 4%);
}



// Highlight Purple	

.slide-body span.highlight--5,
.slide-choice span.highlight--5{
	background: $highlight--5-bgColor;	
	color: $highlight--5-textColor; 
	// background: rgb(217,195,255);		
	// color: #9935DB;
	// background: #F0E4F8;
}

.slide-body span.highlight--5 .katexOuterContainer:not(.ProseMirror-selectednode),
.slide-choice span.highlight--5 .katexOuterContainer:not(.ProseMirror-selectednode){
	background: $highlight--5-bgColor;
	color: $highlight--5-textColor;
	box-shadow: 0px 0px 0px 3px $highlight--5-bgColor;
}

.slide-body span.highlight--5 .katexOuterContainer:hover:not(.ProseMirror-selectednode),
.slide-choice span.highlight--5 .katexOuterContainer:hover:not(.ProseMirror-selectednode){	
	background: darken($highlight--5-bgColor, 4%);	
	box-shadow: 0px 0px 0px 3px darken($highlight--5-bgColor, 4%);
}





// Highlight Yellow		

.slide-body span.highlight--6,
.slide-choice span.highlight--6{
	background: $highlight--6-bgColor;
	color: $highlight--6-textColor;
}

.slide-body span.highlight--6 .katexOuterContainer:not(.ProseMirror-selectednode),
.slide-choice span.highlight--6 .katexOuterContainer:not(.ProseMirror-selectednode){
	background: $highlight--6-bgColor;
	color: $highlight--6-textColor;
	box-shadow: 0px 0px 0px 3px $highlight--6-bgColor;
}

.slide-body span.highlight--6 .katexOuterContainer:hover:not(.ProseMirror-selectednode),
.slide-choice span.highlight--6 .katexOuterContainer:hover:not(.ProseMirror-selectednode){	
	background: darken($highlight--6-bgColor, 4%);	
	box-shadow: 0px 0px 0px 3px darken($highlight--6-bgColor, 4%);
}




// Highlight Pink		

.slide-body span.highlight--7,
.slide-choice span.highlight--7{
	background: $highlight--7-bgColor;
	color: $highlight--7-textColor;
}

.slide-body span.highlight--7 .katexOuterContainer:not(.ProseMirror-selectednode),
.slide-choice span.highlight--7 .katexOuterContainer:not(.ProseMirror-selectednode){
	background: $highlight--7-bgColor;
	color: $highlight--7-textColor;
	box-shadow: 0px 0px 0px 3px $highlight--7-bgColor;
}

.slide-body span.highlight--7 .katexOuterContainer:hover:not(.ProseMirror-selectednode),
.slide-choice span.highlight--7 .katexOuterContainer:hover:not(.ProseMirror-selectednode){	
	background: darken($highlight--7-bgColor, 3%);	
	box-shadow: 0px 0px 0px 3px darken($highlight--7-bgColor, 3%);
}




// Highlight Gray		

.slide-body span.highlight--8,
.slide-choice span.highlight--8{
	background: $highlight--8-bgColor;
	color: $highlight--8-textColor;
}

.slide-body span.highlight--8 .katexOuterContainer:not(.ProseMirror-selectednode),
.slide-choice span.highlight--8 .katexOuterContainer:not(.ProseMirror-selectednode){
	background: $highlight--8-bgColor;
	color: $highlight--8-textColor;
	box-shadow: 0px 0px 0px 3px $highlight--8-bgColor;
}

.slide-body span.highlight--8 .katexOuterContainer:hover:not(.ProseMirror-selectednode),
.slide-choice span.highlight--8 .katexOuterContainer:hover:not(.ProseMirror-selectednode){	
	background: darken($highlight--8-bgColor, 4%);	
	box-shadow: 0px 0px 0px 3px darken($highlight--8-bgColor, 4%);
}






// CODE EXPERIEMENT
.slide-body span.code,
.slide-choice span.code{
	border-radius: 0.2em;
	padding-left: 0.2em;
	font-family: Monaco;	
	font-size: 0.85em;
	position: relative;
	top: -0.05em;
	padding-right: 0.2em;
	margin-left: -0.085em;
	margin-right: -0.085em;
	border: 0.05em solid #DADADA;
	color: #e01e5a;
	background: #F6F6F6;
}