
.ProseMirror .tableWrapper {
  overflow-x: auto;
}
.ProseMirror table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
  background: $blue-extraLight;

}
.ProseMirror td,
.ProseMirror th {
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
}
.ProseMirror .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  width: 4px;
  z-index: 20;
  background-color: #adf;
  pointer-events: none;
}
.ProseMirror.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
/* Give selected cells a blue overlay */
.ProseMirror .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}

 .ProseMirror table {
    margin: 0;
  }
  .ProseMirror th, .ProseMirror td {
    min-width: 1em;
    border: 1px solid #ddd;
    padding: 3px 5px;
  }
  .ProseMirror .tableWrapper {
    margin: 1em 0;
  }
  .ProseMirror th {
    font-weight: bold;
    text-align: left;
  }
