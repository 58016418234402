

.nowPlayingContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
}

.nowPlaying{	
	background: rgb(245,245,253);

	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding: 5px;
	overflow: hidden;

	touch-action: manipulation; // apparently removes delay?
}



.nowPlaying-topPanel{
	position: fixed;
	left: 0px;
	right: 0px;
	top: 0px;
	height: 130px;
	background: $white;
	border-bottom: 1px solid $lineMid;
	z-index: 1;
}

@media only screen and (max-width : 1030px) {
	.nowPlaying-topPanel{
		height: 190px;
	}
}

@media only screen and (min-width : 1400px) {
	.nowPlaying-topPanel{
		height: 175px;
	}
}

.nowPlaying-topPanelCorrect{
	position: fixed;
	left: 0px;
	right: 0px;
	top: -190px;
	height: 200px;
	background: $green;
	border-bottom: 1px solid $green;
	z-index: 5;
}




.nowPlaying--notScanning .nowPlaying-slideContainer,
.nowPlaying--notScanning .nowPlaying-slideContainer *{
	pointer-events: none !important;
}


$nowPlaying-innerMargin: 15px;
.nowPlaying-slideContainerOuter{
	position: absolute;
	top: $nowPlaying-innerMargin;
	right: $nowPlaying-innerMargin;
	left: $nowPlaying-innerMargin;
	bottom: $nowPlaying-innerMargin;
	z-index: 500;
}

.nowPlaying-slideContainerInner{
	
  position: absolute;

  margin: auto;
  top: 0px; 
  left: 0px;
  bottom: 0px;
  right: 0px;

	overflow: hidden;
	
	z-index: 100;
	border-radius: 6px;

	border: 1px solid $lineDark;	
	border-bottom: 1px solid darken($lineDark, 8%);
	box-shadow: 0px 4px 8px 0px rgba(0,0,20,0.04), 0px 8px 16px 0px rgba(0,0,20,0.06);
	background: $white;
}

.nowPlaying--isScanning
.nowPlaying-slideContainerInner{
	box-shadow: 0px 8px 16px 0px rgba(0,0,20,0.18), 0px 16px 32px 0px rgba(0,0,20,0.18);
}

//


.nowPlaying-slideContainerOuter,
.nowPlaying-slideContainerOuter * {
	user-select: none !important;	
}

//

// DARK BG THAT ANIMATES IN WHEN SCAN BG
.nowPlaying-scanBG{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: rgb(63,	60,	76);
	// display: none;
}





@import "nowPlaying-DevFakeButtons.scss";
@import "nowPlaying-HomeLink.scss";
@import "nowPlaying-PopBar.scss";
@import "nowPlaying-CountdownTimer.scss";
@import "nowPlaying-SectionBlob.scss";
@import "nowPlaying-StudentNameBlob.scss";
@import "nowPlaying-EmptyState.scss";

// PERFECT SCORE CELEBRATION
.nowPlaying-perfectScoreCelebrationContainer{
	position: fixed;
	top: 0px;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9990;
	background: rgba(255,255,255,0.5);
}


.nowPlaying-perfectScoreCelebration-label{
	cursor: default;
	font-size: 8vw;
	font-weight: $lato-bold;
	color: $white;

	z-index: 9999;

	text-align: center;

	width: 25vw;
	height: 10vw;

	background: rgb(0,207,127);
	background: linear-gradient(to bottom, rgb(0,207,127) 0%, rgb(41,215,113) 100%); 
	
	border: 1px solid rgb(40,178,122);

	//box-shadow: 0px 12px 24px 0px rgba(0,0,0,0.04);

	border-radius: 1.2vw;

	position: fixed;
	top: calc(50vh - 5vw);
	left: calc(50vw - 12.5vw);

	transform-origin: center center;
}
