.youtubeBlocked-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray-14;
}

.youtubeBlocked-container.inside-editor-insertVideoModal {
  height: 80%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
}

.youtubeBlocked-content {
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  text-align: center;
}

.youtubeBlocked-content.inside-editor-insertVideoModal {
  background-color: $gray-14;
  padding-top: 1.75rem;
}

.youtubeBlocked-content.inside-slideChoiceMedia-video {
  background-color: $gray-14;
  padding-top: 2rem;
}

.youtubeBlocked-icons {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.youtubeBlocked-icons .cookieIcon {
  margin-right: 1rem;
  width: 3rem;
  color: $green;
}

.youtubeBlocked-icons.inside-editor-insertVideoModal .cookieIcon {
  width: 5rem;
}

.youtubeBlocked-icons .videoIcon {
  width: 4.5rem;
  color: $gray-8;
}

.youtubeBlocked-icons.fullSizeIcon .videoIcon {
  width: 70%;
}

.youtubeBlocked-icons .youtubeImageIcon {
  width: 6.5rem;
  background-image: url("https://s3.amazonaws.com/assets.plickers.com/react-assets/images/yt_logo_rgb_light.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.youtubeBlocked-icons .youtubeImageIcon.inside-editor-insertVideoModal {
  width: 10rem;
}

.youtubeBlocked-text {
  margin-bottom: 1.75rem;
  font-size: 1.4rem;
  color: $textDark
}

.youtubeBlocked-text.inside-editor-insertVideoModal {
  font-size: 1.1rem;
}

.youtubeBlocked-text-simple {
  font-size: 1.1rem;
}

.youtubeBlocked-button-link {
  background: none;
  color: $blue;
  text-decoration: underline;
  border: none;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font: inherit;
  cursor: pointer;
}
