$min-height-choice: 52px;
$fontSize-letterblock: 34px;
$lineHeight-letterblock: 1.45;


.slide .slide-choices{
	position: relative;
	top: 10px; // trying this out
}

button.slide-choice{	     
	text-align: left;

	// new e-learning stuff
	min-height: $min-height-choice;
	//margin-bottom: 20px;
	padding-top: 10px;
	padding-bottom: 10px;

	position: relative;
	margin-left: -10px;
	padding-left: 10px;

	//overwrite button
	border: none;
	background: none;
	box-shadow: none;
	outline: none;

	border-radius: 5px;

	text-align: left;
}

button.slide-choice.slide-choice--mediaChoice{
	padding-left: 0px;
	padding-right: 0px;
	margin-left: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
}

//
// E-LEARNING SELECTION BLOB

.slide-choice-letterblock-deviceBlobContainer {
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	z-index: 10;

	display: flex;
	align-items: center;
	justify-content: center;
}

.slide-choice-letterblock-deviceBlob{
	height: 32px;
	width: 32px;
	border-radius: 5px;
	background: transparentize($blue, 0.9);
	// background: $blue;
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
}

.slide-choice-letterblock-deviceBlob-inner{
	position: absolute;
	height: 18px;
	width: 18px;
	border-radius: 9px;
	background: $blue; 
}


// INTERACTIVE TEST


.slide.slide--noDeviceSelectedChoice .slide-choices:hover .slide-choice.slide-choice--isScan{
	opacity: 0.5;
	transition: opacity 100ms linear;
}

.slide.slide--noDeviceSelectedChoice .slide-choices .slide-choice.slide-choice--isScan:hover{
	opacity: 1;
	transition: opacity 50ms linear;
}

.slide-choice.slide-choice--scan:hover{
	background: transparentize($blue-bold, 0.95);
}

.slide-choice.slide-choice--scan.slide-choice--notInteractiveMediaChoice:active{
	background: transparentize($blue-bold, 0.9);	
	-webkit-tap-highlight-color: transparentize($blue-bold, 0.9);
}

.slide-choice.slide-choice--scan.slide-choice.slide-choice--notInteractiveMediaChoice{
	-webkit-tap-highlight-color: transparentize($blue-bold, 0.9);
}



.slide-choice.slide-choice--scan:active .slide-choice-letterblock-deviceBlob{
	background: transparentize($blue, 0.7);
}

.slide-choice.slide-choice--scan:active .slide-choice-letterblock-deviceBlob-inner{
	transform: scale(1.09);
}


.slide.slide--hasDeviceSelectedChoice
.slide-choice.slide-choice--notScan .slide-choice-letterblock-deviceBlobContainer{
	opacity: 0;
}

.slide.slide--noDeviceSelectedChoice
.slide-choice.slide-choice--scan .slide-choice-letterblock *{
	opacity: 0;
	transition: 100ms linear;
}

.slide.slide--noDeviceSelectedChoice
.slide-choice.slide-choice--notScan
.slide-choice-letterblock-deviceBlobContainer{
	opacity: 0;
}


.slide-choice.slide-choice--scan:hover .slide-choice-letterblock *{
	opacity: 1;
}

.slide-choice.slide-choice--scan .slide-choice-letterblock svg,
.slide-choice.slide-choice--scan:hover .slide-choice-letterblock svg{
	opacity: 0;
}

.slide-choice.slide-choice--scan:hover .slide-choice-letterblock .slide-choice-letterblock-deviceBlobContainer{
	opacity: 1;
}

.slide.slide--hasDeviceSelectedChoice
.slide-choice.slide-choice--scan.slide-choice--notDeviceSelected
.slide-choice-letterblock-deviceBlob:not(:hover),
.slide.slide--hasDeviceSelectedChoice
.slide-choice.slide-choice--scan.slide-choice--notDeviceSelected
.slide-choice-letterblock-deviceBlob:not(:active){
	background: $invisible;
}

.slide.slide--hasDeviceSelectedChoice
.slide-choice.slide-choice--scan.slide-choice--notDeviceSelected:hover{
	background: transparentize($blue-bold, 0.98);
}

.slide.slide--hasDeviceSelectedChoice
.slide-choice.slide-choice--scan.slide-choice--deviceSelected:hover{
	background: transparentize($blue-bold, 0.9);
}



.slide.slide--hasDeviceSelectedChoice
.slide-choice.slide-choice--scan.slide-choice--notDeviceSelected
.slide-choice-letterblock-deviceBlob-inner{
	opacity: 0;
}

// for interactive media choices, don't show blob on choice-hover, just on letterblock hover
.slide.slide--hasDeviceSelectedChoice
.slide-choice.slide-choice--scan.slide-choice--notDeviceSelected.slide-choice--notInteractiveMediaChoice:hover
.slide-choice-letterblock-deviceBlob-inner{
	opacity: 0.5;
}

.slide.slide--hasDeviceSelectedChoice
.slide-choice.slide-choice--scan.slide-choice--notDeviceSelected.slide-choice--interactiveMediaChoice
.slide-choice-letterblock:hover
.slide-choice-letterblock-deviceBlob-inner{
	opacity: 0.5;
}




//
// SELECTED
//

.slide.slide--hasDeviceSelectedChoice .slide-choice.slide-choice--deviceSelected{
	background: transparentize($blue, 0.95);
}

// .slide.slide--hasDeviceSelectedChoice .slide-choices .slide-choice.slide-choice--deviceSelected .slide-choice-letterblock *{
// 	opacity: 0;
// }

.slide.slide--hasDeviceSelectedChoice .slide-choices .slide-choice.slide-choice--deviceSelected .slide-choice-letterblock
.slide-choice-letterblock-deviceBlobContainer{
	opacity: 1;
}



.slide.slide--hasDeviceSelectedChoice .slide-choice.slide-choice--scan.slide-choice--notDeviceSelected{
	opacity: 0.5;
}

.slide.slide--hasDeviceSelectedChoice .slide-choices:hover .slide-choice.slide-choice--notDeviceSelected{
	opacity: 1;
}



//
// SURVEY
// UNSELECTED

// blue letters
.slide-choice.slide-choice--survey.slide-choice--notDeviceSelected 
.slide-choice-letterblock--static svg{
	fill: $blue-bold;
}

// blue border
.slide-choice.slide-choice--survey.slide-choice--notDeviceSelected 
.slide-choice-letterblock--static{
	border-color: $blue;
}

//
// SURVEY
// SELECTED

// no letter
.slide-choice.slide-choice--survey.slide-choice--deviceSelected
.slide-choice-letterblock--static svg{
	opacity: 0;
}

// no border
.slide-choice.slide-choice--survey.slide-choice--deviceSelected 
.slide-choice-letterblock--static{
	border-color: $blue;
}


//
// DO NOT SHOW CORRECT / GRADED

// hide the letter of selected
.slide-choice.slide-choice--graded.slide-choice--notScan.slide-choice--notShowCorrect.slide-choice--deviceSelected
.slide-choice-letterblock--static svg{
	opacity: 0;
}

$transitionDelay-showCorrect: 2000ms;
$transitionDuration-showCorrect: 400ms;

//
// SHOW CORRECT – GRADED - CORRECT

// hide the letter of selected
.slide-choice.slide-choice--correct.slide-choice--graded.slide-choice--notScan.slide-choice--showCorrect.slide-choice--deviceSelected
.slide-choice-letterblock--static svg{
	opacity: 0;
}

// green blob
.slide-choice.slide-choice--correct.slide-choice--graded.slide-choice--notScan.slide-choice--showCorrect.slide-choice--deviceSelected
.slide-choice-letterblock-deviceBlob{
	background: transparentize($green, 0.85);
	transition: background $transitionDuration-showCorrect linear;
	transition-delay: $transitionDelay-showCorrect;
}

.slide-choice.slide-choice--correct.slide-choice--graded.slide-choice--notScan.slide-choice--showCorrect.slide-choice--deviceSelected
.slide-choice-letterblock-deviceBlob-inner{
	background: $green;
	transition: background $transitionDuration-showCorrect linear;
	transition-delay: $transitionDelay-showCorrect;
}

// background is green
.slide-choice.slide-choice--correct.slide-choice--graded.slide-choice--notScan.slide-choice--showCorrect.slide-choice--deviceSelected{
	background: transparentize($green, 0.9);
	transition: background $transitionDuration-showCorrect linear;
	transition-delay: $transitionDelay-showCorrect;
}

// TO DO: The sparkling thing


//
// SHOW CORRECT – GRADED - INCORRECT

// Their choice
// Make the letter of their choice
.slide-choice.slide-choice--incorrect.slide-choice--graded.slide-choice--notScan.slide-choice--showCorrect.slide-choice--deviceSelected
.slide-choice-letterblock--static svg{
	opacity: 0;
}

// gray blob
.slide-choice.slide-choice--incorrect.slide-choice--graded.slide-choice--notScan.slide-choice--showCorrect.slide-choice--deviceSelected
.slide-choice-letterblock-deviceBlob{
	background: transparentize($gray-4, 0.85);
	transition: all $transitionDuration-showCorrect linear;
	transition-delay: $transitionDelay-showCorrect;
}

.slide-choice.slide-choice--incorrect.slide-choice--graded.slide-choice--notScan.slide-choice--showCorrect.slide-choice--deviceSelected
.slide-choice-letterblock-deviceBlob-inner{
	background: $gray-4;
	transition: all $transitionDuration-showCorrect linear;
	transition-delay: $transitionDelay-showCorrect;
}

// background is gray
.slide-choice.slide-choice--incorrect.slide-choice--graded.slide-choice--notScan.slide-choice--showCorrect.slide-choice--deviceSelected{
	background: transparentize($gray-4, 0.9);
	transition: all $transitionDuration-showCorrect linear;
	transition-delay: $transitionDelay-showCorrect;
}

// Make all incorrect answers semi-opacity
.slide-choice.slide-choice--incorrect.slide-choice--graded.slide-choice--notScan.slide-choice--showCorrect{
	opacity: 0.4;
	transition: all $transitionDuration-showCorrect linear;
	transition-delay: $transitionDelay-showCorrect;
}

// Make the letter of the correct, green
.slide-choice.slide-choice--correct.slide-choice--graded.slide-choice--notScan.slide-choice--showCorrect.slide-choice--notDeviceSelected
.slide-choice-letterblock--static{
	border-color: $green;
	background: transparentize($green, 0.9);
	transition: all $transitionDuration-showCorrect linear;
	transition-delay: $transitionDelay-showCorrect;
}
.slide-choice.slide-choice--correct.slide-choice--graded.slide-choice--notScan.slide-choice--showCorrect.slide-choice--notDeviceSelected
.slide-choice-letterblock--static svg{
	fill: $green;
	transition: all $transitionDuration-showCorrect linear;
	transition-delay: $transitionDelay-showCorrect;
}


//
//

.slide .slide-choice,
.fontSizeChoicesArray .slide-choice{
	min-height: $min-height-choice;
	position: relative;
}
.fontSizeChoicesArray .slide-choice{
	margin-bottom: 20px;
}

.slide--editor .slide-choice--focused{
	z-index: 50; // so shortcut hint appears above
}

.fontSizeChoicesArray .slide-choice
.slide-choice-text{
	overflow-x: scroll !important;
	// allows us to measure if overflow to downscale font size
}


.slide-choices .slide-choice:last-child,
.fontSizeChoicesArray .slide-choice:last-child{
	margin-bottom: 0px; // new
}

//
// For Body Center Media Center, no margin on last two
.slide--template--bodyCenter .slide-choices .slide-choice:nth-child(n+3),
.slide--template--bodyCenterMediaCenter .slide-choices .slide-choice:nth-child(n+3),
.fontSizeChoicesArray--template--bodyCenter .slide-choice:nth-child(n+3),
.fontSizeChoicesArray--template--bodyCenterMediaCenter .slide-choice:nth-child(n+3){
	margin-bottom: 0px; // new
}



.slide.slide--template--bodyLeft
.slide-choice{
  width: 100%;
}

.slide.slide--template--bodyLeftMediaRight
.slide-choice{
  width: 100%;
}

.slide.slide--template--bodyRightMediaLeft
.slide-choice{
  width: 100%;
}

.slide.slide--template--bodyCenter .slide-choice,
.fontSizeChoicesArray--template--bodyCenter .slide-choice{
  width: 540px;
}

.fontSizeChoicesArray--template--bodyCenter .slide-choice:nth-child(n+3),
.fontSizeChoicesArray--template--bodyCenterMediaCenter .slide-choice:nth-child(n+3){
	margin-bottom: 0;
}

//

// REFACTOR TO TAKE ACCOUNT OF NUMBER OF IMAGES
.slide.slide--template--bodyCenterChoicesMedia .slide-choice,
.slide.slide--template--bodyLeftMediaRightChoicesMedia .slide-choice{
  width: 25%;
  height: 342px;
  margin-bottom: 0px;
}


//


.slide.slide--template--bodyCenterMediaCenter .slide-choice,
.fontSizeChoicesArray--template--bodyCenterMediaCenter .slide-choice{
  width: 540px;
}

//

.slide .slide-choice-letterblock,
.fontSizeChoicesArray .slide-choice-letterblock,{
	position: absolute;
	width: $min-height-choice;
	height: $min-height-choice;
	top: 0px;
	left: 0px;
	pointer-events: auto;
	z-index: 50; // make clickable
}


//
// THIS IS A BIT FUNKY

.slide-choice-content{
	height: 100%;
	width: 100%;
	position: relative;
}

.slide-choice-mediaContainer{
	position: absolute;
	top: -2px;
	left: 2px;
	right: 2px;
	bottom: 58px; // refactor
}




// hide choice media containers for text-choice
.slide.slide--template--bodyLeft .slide-choice-mediaContainer,
.slide.slide--template--bodyLeftMediaRight .slide-choice-mediaContainer,
.slide.slide--template--bodyRightMediaLeft .slide-choice-mediaContainer,
.slide.slide--template--bodyCenterMediaCenter .slide-choice-mediaContainer,
.slide.slide--template--bodyCenter .slide-choice-mediaContainer{
  display: none; 
}

// hide text container if media choices
.slide.slide--template--bodyCenterChoicesMedia .slide-choice-text,
.slide.slide--template--bodyLeftMediaRightChoicesMedia .slide-choice-text{
  display: none;
}

// hide remove choice button if media, remove is in empty state
.slide.slide--template--bodyCenterChoicesMedia .slide-choice-deleteBtn,
.slide.slide--template--bodyLeftMediaRightChoicesMedia .slide-choice-deleteBtn{
  display: none; 
}



//
//

.slide-choice-text{
	position: relative;

	font-size: inherit;
	font-weight: 700; // bold
	color: rgb(0,0,0);
	box-sizing: border-box;
	background: rgba(0,0,0,0);
	padding-left: 8px;
	//padding-top: 3px; I'm not sure we need this?
	//padding-bottom: 3px; I'm not sure we need this?
	border: 1px solid rgba(0,0,0,0);
	
	white-space: pre-wrap;
	padding-right: 2px;
	word-wrap: break-word;
	overflow: hidden;
	letter-spacing: 0.01em;

	width: calc(100% - 60px);
	// margin-left: 60px; old
	left: 60px; // experimental, solves cursor issues potentially on letterblock
	min-height: $min-height-choice;
}

// MEDIA CHOICES

.slide.slide--template--bodyCenterChoicesMedia .slide-choice-letterblock,
.slide.slide--template--bodyLeftMediaRightChoicesMedia .slide-choice-letterblock{
	position: absolute;
	width: $min-height-choice;
	height: $min-height-choice;
	top: initial;
	left: calc(50% - (#{$min-height-choice}/2));
	bottom: 0px;
	// pointer-events: auto; // not sure why this is here
	z-index: 100; // make clickable
}

// NOW PLAYING LOWER OPACITY IF INCORRECT
// might need to fix
.slide-choice.slide-choice--showCorrect.slide-choice--incorrect.slide-choice--scheme1.slide-choice--notAsk.slide-choice--notScan{
	opacity: 0.5;
}

//
// EDITOR
//

// placeholder - will need a refactor
// PLACEHOLDER STYLING - probably needs refactor
.slide.slide--editor .slide-choice-text-placeholder {
  color: rgb(198, 198, 212);
}

.slide--editor .slide-choice-text {
	cursor: text;
	white-space: pre-wrap !important; // for prosemirror firefox support  
}

.slide--editor:hover .slide-choice .slide-choice-text {
	border-color: $lineLight;
}

.slide--editor .slide-choice:hover .slide-choice-text {
	border-color: $lineMid;	
}

.slide--editor .slide-choice .slide-choice-text:hover {
	border-color: $gray-11;	
}

.slide--editor .slide-choice.slide-choice--focused .slide-choice-text{
	border-color: $blue;
	outline: none;
	box-shadow: none;
}

@import 'slide-Choice-LetterBlock';
@import 'slide-Choice-DeleteBtn'; // in editor
@import 'slide-Choice-AddMediaBtn'; // in editor


@import 'slide-Choice-Media'; // media choices

@import 'slide-Choice-Graph'; // reseponse Graph

@import 'slide-Choice-ShortcutHints'; // tooltips

