
//--- TYPOGRAPHY ---// 

$lato-regular: 	400;
$lato-medium: 	500;
$lato-semibold: 600;
$lato-bold: 	700;
$lato-heavy: 	800;
$lato-black: 	900;

/* Webfont: Lato-Medium */@font-face {
    font-family: 'Plickers Lato';         
    src:  url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Medium.setcetera.woff2') format('woff2'), url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Medium.setcetera.woff') format('woff');    
    font-style: normal;
    font-display: auto;
    font-weight: $lato-medium;
    text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Semibold */@font-face {
    font-family: 'Plickers Lato';       
    src:  url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Semibold.setcetera.woff2') format('woff2'), url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Semibold.setcetera.woff') format('woff');    
    font-style: normal;
    font-display: auto;
    font-weight: $lato-semibold;
    text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Bold */@font-face {
    font-family: 'Plickers Lato';   
    src: url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Bold.setcetera.woff2') format('woff2'), url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Bold.setcetera.woff') format('woff');        
    font-style: normal;
    font-display: auto;
    font-weight: $lato-bold;
    text-rendering: optimizeLegibility;
}



/* Webfont: Lato-Heavy */@font-face {
    font-family: 'Plickers Lato';    
    src:  url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Heavy.setcetera.woff2') format('woff2'), url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Heavy.setcetera.woff') format('woff');    
    font-style: normal;
    font-display: auto;
    font-weight: $lato-heavy;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Black */@font-face {
    font-family: 'Plickers Lato';    
    src:  url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Black.student1.woff2') format('woff2'), url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Black.student1.woff') format('woff');    
    font-style: normal;
    font-display: auto;
    font-weight: $lato-black;
    text-rendering: optimizeLegibility;
}




///

.App, body, input, textarea, button {
	font-family: 'Plickers Lato';
	-webkit-font-smoothing: antialiased;
}

