.slide-choice-content-shortcutHints{
	position: absolute;
	bottom: -18px;
	bottom: -22px;
	//

	top: 54px;
	top: -27px;

	left: 54px; 
	height: 21px;

	left: 60px; 

	transform-origin: bottom left;

	user-select: none;
	// background: $tempr;

}

// HIDE FOR MEDIA CHOICES
.slide--template--bodyLeftMediaRightChoicesMedia .slide-choice-content-shortcutHints,
.slide--template--bodyCenterChoicesMedia .slide-choice-content-shortcutHints{
	opacity: 0;
}


.slide-choice-content-shortcutHint{
	
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;

	line-height: 1.9;
	// display: flex;
	// align-items: center;
	// justify-content: center;
	// background: $tempb;
	// background: rgba(255,255,255,0.5);

	border-radius: 3px;
	
	padding-left: 7px;
	padding-right: 7px;
	
	font-size: 12px;
	font-weight: $lato-semibold;
	color: $textMid;
	letter-spacing: 0.015em;

	pointer-events: none;

	overflow: hidden;
	white-space: nowrap;

	opacity: 0;
	user-select: none;
	// background: $gray-5;	


	color: $white;
	background: rgba(76,76,90,0.9);


	// background: white;
	// border: 1px solid $lineMid;
	// border-bottom: 1px solid darken($lineMid, 5%);
	// color: $textDark;
	// box-shadow: 0px 1px 2px 0px rgba(0,0,20,0.06);
	
}

.slide-choice-content-shortcutHint strong{
	font-weight: $lato-bold;
	// color: $textBlackDark;
	// margin-right: 2px;
}

.slide-choices--incomplete .slide-choice--graded:first-child 
.slide-choice-content-shortcutHint.slide-choice-content-shortcutHint--incompleteFirst{
	opacity: 1;	
}

.slide-choices--incomplete .slide-choice--graded:nth-child(2) 
.slide-choice-content-shortcutHint.slide-choice-content-shortcutHint--incompleteMiddle{
	opacity: 1;
}

.slide-choices--incomplete .slide-choice--graded:nth-child(3) 
.slide-choice-content-shortcutHint.slide-choice-content-shortcutHint--incompleteMiddle{
	opacity: 1;	
}

.slide-choices--incomplete .slide-choice--graded:last-child 
.slide-choice-content-shortcutHint.slide-choice-content-shortcutHint--incompleteLast{
	opacity: 1;
}

// COMPLETE

.slide-choices--complete .slide-choice--graded.slide-choice--incorrect
.slide-choice-content-shortcutHint.slide-choice-content-shortcutHint--completeIncorrect{
	opacity: 1;	
}

.slide-choices--complete .slide-choice--graded.slide-choice--correct
.slide-choice-content-shortcutHint.slide-choice-content-shortcutHint--completeCorrectNotLast{
	opacity: 1;	
}

.slide-choices--complete .slide-choice--graded.slide-choice--correct:last-child
.slide-choice-content-shortcutHint.slide-choice-content-shortcutHint--completeCorrectLast{
	opacity: 1;	
}

.slide-choices--complete .slide-choice--graded.slide-choice--correct:last-child
.slide-choice-content-shortcutHint.slide-choice-content-shortcutHint--completeCorrectNotLast{
	opacity: 0;	
}


//
// SURVEY

.slide-choices--incomplete .slide-choice--survey
.slide-choice-content-shortcutHint.slide-choice-content-shortcutHint--survey{
	opacity: 1;	
}

.slide-choices--incomplete .slide-choice--survey:last-child
.slide-choice-content-shortcutHint.slide-choice-content-shortcutHint--surveyLast{
	opacity: 1;	
}