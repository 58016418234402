.nowPlaying-topPanel-sectionBlobContainer{
	position: fixed;
	top: 0px;
	left: 0px;	
	z-index: 9999;
	height: 32px;
	
	padding-top: 12px;
	padding-left: 12px;
	padding-right: 12px;

	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.nowPlaying-topPanel-sectionBlob{
	
	display: flex;
	align-items: center;
	justify-content: center;

	height: 22px;
	min-width: 24px;

	border-radius: 4px;

	cursor: default;
	user-select: none;


}


.nowPlaying-topPanel-sectionBlob-label{
	opacity: 0;
	// width: auto;
	width: 0px;
	overflow: hidden;
	padding-left: 6px;
	padding-right: 6px;

	font-size: 13.5px;
	font-weight: $lato-bold;
	color: $white;
	letter-spacing: 0.02em;

	max-width: 190px;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .nowPlaying-topPanel-sectionBlob:hover
.nowPlaying-topPanel-sectionBlob-label{
	opacity: 1;
	width: auto;
}

// .nowPlaying-topPanel-sectionBlobContainer:hover
.nowPlaying-topPanel-sectionBlob-label{
	opacity: 1;
	width: auto;
}


//
.nowPlaying-topPanel-sectionBlobContainer--notScanning{
	opacity: 1;
	transition: opacity 200ms linear;
	transition-delay: 100ms;
}
.nowPlaying-topPanel-sectionBlobContainer--scanning{
	opacity: 0;
	transition: opacity 200ms linear;	
}

//

.nowPlaying-topPanel-sectionBlob--classColor--blue{
	background: $classColor-blue;
}

.nowPlaying-topPanel-sectionBlob--classColor--purple{
	background: $classColor-purple;
}

.nowPlaying-topPanel-sectionBlob--classColor--red{
	background: $classColor-red;
}

.nowPlaying-topPanel-sectionBlob--classColor--orange{
	background: $classColor-orange;
}

.nowPlaying-topPanel-sectionBlob--classColor--green{
	background: $classColor-green;
}

.nowPlaying-topPanel-sectionBlob--classColor--sky{
	background: $classColor-sky;
}

.nowPlaying-topPanel-sectionBlob--classColor--pink{
	background: $classColor-pink;
}

.nowPlaying-topPanel-sectionBlob--classColor--teal{
	background: $classColor-teal;
}

.nowPlaying-topPanel-sectionBlob--classColor--grey{
	background: $classColor-grey;
}