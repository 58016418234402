
// addChoiceMediaBtn 

.slide-choice-addChoiceMediaBtn{
	width: 210px;
	
	border-radius: 4px;
	position: absolute;
	top: 6px;
	height: 40px;
	// bottom: 6px;
	right: 6px;

	cursor: pointer;
	
	text-align: center;
	box-sizing: border-box;

	border-radius: 4px;
	
	opacity: 0;
	
	-webkit-appearance: none;
	
	font-size: 20px;
	font-weight: $lato-bold;
	letter-spacing: 0.02em;
	display: flex;
	align-items: center;
	justify-content: center;

	background: $gray-15;
	color: $gray-6;
}

// shortLabel 
.slide-choice-addChoiceMediaBtn--shortLabel{
	width: 85px;
}

.editor-canvas .slide-choice-addChoiceMediaBtn{
	visibility: hidden;
	pointer-events: none;
}

.editor-canvas--showChoiceAddMediaBtn .slide-choice-addChoiceMediaBtn{
	visibility: visible;
	pointer-events: auto;
}

//

// .slide:hover .slide-choices .slide-choice .slide-choice-addChoiceMediaBtn {
	
// 	opacity: 0; // temp
// }


// .slide .slide-choices:hover .slide-choice .slide-choice-addChoiceMediaBtn .editorIcon {
// 	opacity: 0.25;
// }

// .slide:hover  .slide-choices:hover .slide-choices .slide-choice.choice--empty .slide-choice-addChoiceMediaBtn .editorIcon {
// 	opacity: 0.3;
// }

.slide .slide-choices .slide-choice:hover .slide-choice-addChoiceMediaBtn {
	opacity: 0.8;
}

.slide .slide-choices .slide-choice--focused:hover .slide-choice-addChoiceMediaBtn{
	opacity: 1;
}

.slide .slide-choices .slide-choice:hover .slide-choice-addChoiceMediaBtn:hover {
	opacity: 1;
	background: $blue-extraLight;
	color: $blue;
}

// .slide .slide-choices .slide-choice.choice--empty:hover .slide-choice-addChoiceMediaBtn .editorIcon {
// 	opacity: 0.8;
// 	fill: $red;
// }

// //need to refactor
// .slide .slide-choices .slide-choice:focus-within .slide-choice-addChoiceMediaBtn .editorIcon {
// 	opacity: 0.4;
// }

// .slide .slide-choices .slide-choice .slide-choice-addChoiceMediaBtn:hover .editorIcon {
// 	fill: $red;
// 	opacity: 1;
// }

// //


// .slide:hover .slide-choices .slide-choice .slide-choice-addChoiceMediaBtn{
// 	opacity: 1;
// }

// .slide .slide-choices:hover .slide-choice .slide-choice-addChoiceMediaBtn {
// 	background: rgba(0,0,25,0.01);
// }

// .slide .slide-choices .slide-choice:hover .slide-choice-addChoiceMediaBtn {
// 	background: rgba(0,0,25,0.02);
// }

// //need to refactor
// .slide .slide-choices .slide-choice:focus-within .slide-choice-addChoiceMediaBtn {
// 	opacity: 0;
// }


// .slide .slide-choices .slide-choice .slide-choice-addChoiceMediaBtn:hover {
// 	background: transparentize($red, 0.85);
// 	opacity: 1;
// }

