.slide{
	width: 1280px;
	height: 800px;

	//border: 1px solid rgb(180,180,200); don't need it in elearning
	
	background: $white;

	padding: 60px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	background: white;

	// user-select: none !important;
	// pointer-events: none !important;
	// cursor: default !important;

	position: relative;

	transform-origin: top left; // I think this is right to put here
}

//
// E-LEARNING
// .nowPlaying-slideContainerInner--notScanning,
// .nowPlaying-slideContainerInner--notScanning * {
// 	user-select: none !important;
// 	pointer-events: none !important;
// 	cursor: default !important;
// }



// DEV - HIDE SLIDE IF INACTIVE

.slide--editor--hidden{
	content-visibility: hidden;
	display: none;
	user-select: none !important;
}
// .slide--editor--hidden{
// 	visibility: hidden;
// 	height: 1px;
// 	width: 1px;
// }


.editor-canvas .slide{
	background: $white;
	
	border-radius: 5px;
	// box-shadow: 0px 3px 4px -1px rgba(0,0,40,0.1);

	border: 1px solid $lineDark;
	border-bottom: 1px solid darken($lineDark, 8%);
	//background: $white;
	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.08);

	cursor: default;
}

// Paragraph - matches legacy behaviour I think
.slide p,
.slide-body p{
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
}





.richTextEditorContainer-outputContainer-slideContainer--large
.slide{
	box-shadow: 0px 6px 12px 0px rgba(0,0,80,0.08);
	border-radius: 5px;
}



@import 'slide-Body';
@import 'slide-Media';
@import 'slide-Choices';
@import 'slide-Choice';
@import 'slide-MathsAndScience'; // friendly fractions for now
@import 'slide-RichTextFormatting';

// part of the shared-with-editor styling in main react app
@import 'slide-CanvasImageControls';
@import 'slide-CanvasSoundPlayer';
@import 'slide-CanvasVideoControls';

//
// SELECTION HACKS
//

// Math Selection we add a class for this
.slide.slide--editor .slide-body.slide-body--focused span.katex-container *::selection,
.slide.slide--editor .slide-choice.slide-choice--focused span.katex-container *::selection{
  background: $invisible !important;
  // katex-container-selected covers all of our bases
}

$defaultSelectionBackgroundColor: rgba(0,116,255,0.29);

.slide-choice-text{
	//user-select: none !important;
}

.slide-choice-text::selection,
.slide-choice-text *::selection {
	// background: $tempr !important;
	background: $invisible !important;
}

.slide-body::selection,
.slide-body *::selection {
	// background: $tempr !important;	
	background: $invisible !important;
}

.slide-body.slide-body--focused::selection,
.slide-body.slide-body--focused *::selection{
	// background: $tempg !important;
	background: $defaultSelectionBackgroundColor !important;
}

.slide-choice--focused .slide-choice-text::selection,
.slide-choice--focused .slide-choice-text *::selection{
	// background: $tempg !important;
	background: $defaultSelectionBackgroundColor !important;
}





// .slide.slide--editor .slide-body.slide-body--focused span.katex-container.katex-container--selected *::selection,
// .slide.slide--editor .slide-choice.slide-choice--focused span.katex-container.katex-container--selected *::selection{
//   background: $invisible !important;
//   background: red !important;
//   // katex-container-selected covers all of our bases
// }


// .slide-body.slide-body--focused::selection
// .slide-choice-text *::selection{
// 	background: $tempg;
// }


// .slide-body--focused::selection {
// 	background: $tempg !important; // chrome color, may be different different brower
// }

// .slide-body--focused .slide-choice-text::selection,
// .slide-choice--focused .slide-choice-text::selection {
// 	background: #B4D7FF !important; // chrome color, may be different different brower
// }


.slide-choice-text-placeholder{
	pointer-events: none;
}

//
// FOR FONT-SIZE-CALCULATOR
// add a small character to body and choices to act as a buffer
.fontSizeCalculator-ghostArrays-bodyArrayContainer
.slide-body p:last-child:after{
	content: "–" !important;
}
 
.fontSizeCalculator-ghostArrays-choiceArrayContainer
.slide-choice-content:after {
	content: "–" !important;
}

