

.nowPlaying-home{
	
	position: fixed;
	top: 16px;
	right: 16px;
	width: 42px;
	height: 42px;
	border-radius: 21px;
	z-index: 9999;

	color: white;
	background: rgba(0,0,0,0.25);	
	
	opacity: 0.4;

	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 150ms cubic-bezier(.2, .3, .25, .9);

}

.nowPlaying-home svg{	
	height: 18px;
	width: 18px;
	fill: $white;
	transition: all 150ms cubic-bezier(.2, .3, .25, .9);
}

.nowPlaying-home:hover{
	opacity: 0.8;
	transform: scale(1.35);
	transition: all 150ms cubic-bezier(.2, .3, .25, .9);
}

.nowPlaying-home:active{
	opacity: 1;
}

.nowPlaying-home:hover svg{
	transform: scale(1.05);
	transition: all 150ms cubic-bezier(.2, .3, .25, .9);
}