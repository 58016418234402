.slide-choice-graph{
	position: absolute;
	//bottom: -11px;
	bottom: -4px; // fits better
	left: calc(60px + 20px);
	right: 0px;
	height: 8px;
	
	border-radius: 5px;
}

.slide-choice-graph-bar{
	position: absolute;
	left: 0px;
	top: 0px;
	height: 100%;
	
	border-radius: 5px;
}

.slide-choice.slide-choice--showResponseCounts
.slide-choice-graph{
	right: 26px; // make space for response counts
}

/// MEDIA CHOICES SUPPORT

.slide--template--bodyCenterChoicesMedia .slide-choice-graph,
.slide--template--bodyLeftMediaRightChoicesMedia .slide-choice-graph{
	bottom: -16px;
	left: 9px;
	right: 9px;
}


.slide-choice.slide-choice--notShowCorrect .slide-choice-graph-bar{
	background: $blue;
	transition: all $transitionDuration-showCorrect linear;	
}

.slide-choice.slide-choice--showCorrect.slide-choice--correct .slide-choice-graph-bar{
	background: $green;
	transition: all $transitionDuration-showCorrect linear;
	transition-delay: $transitionDelay-showCorrect;
}

// No scheme fallback
.slide-choice.slide-choice--showCorrect.slide-choice--incorrect .slide-choice-graph-bar{
	background: $gray-11;
	transition: all $transitionDuration-showCorrect linear;
	transition-delay: $transitionDelay-showCorrect;
}

.slide-choice.slide-choice--survey .slide-choice-graph-bar{
	background: $blue;
	transition: all $transitionDuration-showCorrect linear;
}


// REPORT VIEW

.slide--reportView .slide-choice-graph-bar{
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;

	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

.slide--reportView .slide-choice-graph{
	background: rgb(240,240,245);
}

.slide--reportView .slide-choice.slide-choice--correct .slide-choice-graph-bar{
	background: $green;
	transition: all $transitionDuration-showCorrect linear;
	transition-delay: $transitionDelay-showCorrect;
}

.slide--reportView .slide-choice.slide-choice--incorrect .slide-choice-graph-bar{
	background: $red;
	transition: all $transitionDuration-showCorrect linear;
	transition-delay: $transitionDelay-showCorrect;
}

.slide--reportView .slide-choice.slide-choice--survey .slide-choice-graph-bar{
	background: $blue;
	transition: all $transitionDuration-showCorrect linear;
}

///
///RESPONSE COUNT
///

.slide-choice-responseCount{
	position: absolute;
	bottom: -20px;
	right: -24px;
	width: 44px;
	height: 44px;	
	font-size: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	color: $textDark;
}

.slide-choice-responseCount--isZero{
	opacity: 0.4;
}

// Media Choices Support
.slide--template--bodyCenterChoicesMedia .slide-choice-responseCount,
.slide--template--bodyLeftMediaRightChoicesMedia .slide-choice-responseCount{
	// background: $tempr;
	bottom: 5px;
	right: 10px;
}