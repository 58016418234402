
button.homePage-sectionCard-logOutButton{
	
	border: none;
	background: none;
	outline: none;
	box-shadow: none;
	

	position: absolute;
	bottom: -50px;	
	width: calc(100% - 40px);
	left: 20px;
	height: 36px;

	border-radius: 3px;

	z-index: 0;
	
	font-size: 14.5px;
	font-weight: $lato-bold;

	letter-spacing: 0.01em;

	cursor: pointer;
	user-select: none;

	background: transparentize($red, 0.8);
	color: $red;
}

button.homePage-sectionCard-logOutButton:hover{
	background: transparentize($red, 0.7);	
}
button.homePage-sectionCard-logOutButton:active{
	background: transparentize($red, 0.5);
}
button.homePage-sectionCard-logOutButton-label{
	position: absolute;
	margin: auto;
}




.homePage-sectionCard-countDownPop{
	
	border: none;
	background: none;
	outline: none;
	box-shadow: none;
	

	position: absolute;
	bottom: -40px;	
	width: calc(100% - 40px);
	left: 20px;
	height: 30px;

	border-radius: 5px;

	z-index: 0;
	
	color: $white;
	font-size: 14.5px;
	font-weight: $lato-bold;

	letter-spacing: 0.01em;

	cursor: pointer;
	user-select: none;

	background: #313141;
	border: 1px solid darken(#313141, 5%);

	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 4px;

	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.08);
}

.homePage-sectionCard-countDownPop-label
.homePage-sectionCard-countDownPop-number{
	
	
}

.homePage-sectionCard-countDownPop-label{
	margin-right: auto;	
}

.homePage-sectionCard-countDownPop-number{
	opacity: 0.5;
	width: 18px;
}

.homePage-sectionCard-countDownPop-number--active{
	opacity: 1;
}