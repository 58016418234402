
// REFACTOR TO PUT ELSEWHERE

// .slide-choice:first-child{
// 	position: relative;
// 	z-index: 500;
// }

.slide-choice-media{
	width: 100%;
	height: 100%;
}


//
//
// SHARED STYLING
.slide-choice-mediaContainer--image,
.slide-choice-mediaContainer--gif,
.slide-choice-mediaContainer--video,
.slide-choice-mediaContainer--sound{	
	background: $white;

	border-radius: 12px;
	border: 1px solid $gray-13;
	box-shadow: 0px 2px 2px 0px rgba(0,0,20,0.05);

	overflow: hidden;
}

// might need to refactor this into per-choice styling
// .slide-choice--choiceMediaZoomed .slide-choice-mediaContainer--image,
// .slide-choice--choiceMediaZoomed .slide-choice-mediaContainer--gif,
// .slide-choice--choiceMediaZoomed .slide-choice-mediaContainer--video,
// .slide-choice--choiceMediaZoomed .slide-choice-mediaContainer--sound{
// 	background: none;
// 	border: none;
// 	box-shadow: none;
// }

// .slide-choice--choiceMediaZoomed .slide-choice-letterblock{
// 	opacity: 0;
// 	transition: opacity 100ms linear;
// }


//
// STATIC
// overlay provided for editor interaction 
//


.slide-choice-media--video--static,
.slide-choice-media--sound--static{
	pointer-events: none !important;
	user-select: none !important;
}

//
// overlay used in editor

.slide-choice-media--video--static-overlay{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	//background: $tempg;
	cursor: pointer;

	z-index: 2;
}

.slide-choice-media--video--static-overlay-internalViewSpacer{
	position: absolute;
	left: 0px;
	top: 0px;

	//background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;
}

.slide-choice-media--video--static-overlay-playIconContainer{
	transform-origin: center center;
	width: 97px;
	height: 68px;

	border-radius: 18px;
	background: rgba(0,0,5,0.65);

	display: flex;
	align-items: center;
	justify-content: center;
}

.slide-choice-media--video--static-overlay-playIconContainer svg{
	fill: $white;
	height: 30px;
	width: 30px;

	position: relative;
	right: -1px;
}


.slide-choices .slide-choice-mediaContainer .slide-choice-media--video--static-overlay-playIconContainer{
	opacity: 0;	
	//opacity: 1 !important;
	// opacity: 0.5 !important; 
}

.slide-choices:hover .slide-choice-mediaContainer .slide-choice-media--video--static-overlay-playIconContainer{
	opacity: 0.2;
}

.slide-choices:hover .slide-choice-mediaContainer:hover .slide-choice-media--video--static-overlay-playIconContainer{
	opacity: 0.9;
	// background: #FF0200; // match youtube red
}

.slide-choices:hover .slide-choice-mediaContainer:hover .slide-choice-media--video--static-overlay-playIconContainer:hover{
	opacity: 1;
	// background: #FF0200; // match youtube red
}

// .slide-choices:hover .slide-choice-mediaContainer:hover .slide-choice-media-topRightBtnContainer:hover button.slide-choice-media--image-removeImage:hover,
// .slide-choices:hover .slide-choice-mediaContainer:hover .slide-choice-media-topRightBtnContainer:hover button.slide-choice-media--gif-removeGif:hover{
// 	opacity: 1;
// 	background: $red;
// }












//
//
// IMAGE
//
//

.slide-choice-mediaContainer--image,
.slide-choice-mediaContainer--gif{
	cursor: pointer; // edit for image, replace for gif
	overflow: hidden;
	box-shadow: 0px 2px 2px 0px rgba(0,0,20,0.08);
}

.slide-choice-mediaContainer--image--static,
.slide-choice-mediaContainer--gif--static{
	cursor: default;
}

.slide-choice-mediaContainer--image img.slide-choice-media--image-image,
.slide-choice-mediaContainer--gif .slide-choice-media--gif-gif img{
	width: 100%;
	height: 100%;

	object-fit: cover;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 5;
}

.slide-choice-mediaContainer--image img.slide-choice-media--image-image--fit{
	object-fit: contain;
}

.slide-choice-mediaContainer--image img.slide-media--image-image--placeholder{
	z-index: 10;
	pointer-events: none;
}

// Placeholder State overlay and spinner

.slide-choice-media--image-placeholderOverlay{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	// background: $tempr;
	// background: rgba(0,0,20,0.15);
	background: none;

	z-index: 10;

}

.slide-choice-media--image-placeholderOverlay--show{
	opacity: 1;
	transition: opacity 50ms cubic-bezier(.4,.17,.5,1.05);
	cursor: default !important;
}

.slide-choice-media--image-placeholderOverlay--hide{
	opacity: 0;
	transition: opacity 50ms cubic-bezier(.4,.17,.5,1.05);
	pointer-events: none !important;
}




.slide-choice-mediaContainer--image--fallback,
.slide-choice-mediaContainer--gif--fallback{
	position: absolute;
	top: 20px;
	left: 20px;
	right: 20px;
	bottom: 20px;
	background: $gray-15;
	border-radius: 10px;
}

// ZOOMABLE

.slide-choice-media--image-imageCover,
.slide-choice-media--gif-gifCover{
	position: absolute;
	overflow: hidden;
	border-radius: 6px;
}

.slide-choice-media--image-imageContainer,
.slide-choice-media--gif-gifContainer{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.slide-choice-media--image-imageContainer img.slide-choice-media--image-image,
.slide-choice-media--gif-gifContainer img.slide-choice-media--gif{
	user-select: none;
	height: 100%;
	width: 100%;
	position: relative;
	z-index: 10;
	object-fit: unset;
}



.slide-choice-media-topRightBtnContainer{
	position: absolute;
	top: 0px;
	right: 0px;
	width: 40px;
	height: 40px;

	transform-origin: top right;
	z-index: 50;

	// background: $tempr;
}

.slide-choice-media-topRightBtnContainer--placeholder{
	cursor: default;
}


//
// REMOVE IMAGE (EDITOR) AND EXPAND IMAGE (STATIC)

button.slide-choice-media--image-removeImage,
button.slide-choice-media--image-fitImage,
button.slide-choice-media--image-expandImage,
button.slide-choice-media--gif-removeGif{
	position: absolute;
	top: 6px;
	right: 6px;

	transform-origin: center center;

	width: 24px;
	height: 24px;

	background: none;
	border: none;
	outline: none;
	box-shadow: none;

	background: rgba(0,0,20,0.9);

	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}

button.slide-choice-media--image-fitImage{
	right: unset;
	left: 6px;
	width: 36px;
}

button.slide-choice-media--image-expandImage{
	width: 30px;
	height: 30px;
	border-radius: 6px;
}

.slide-choice-media--image-removeImage svg.editorIcon,
.slide-choice-media--image-fitImage svg.editorIcon,
.slide-choice-media--image-expandImage svg.editorIcon,
.slide-choice-media--gif-removeGif svg.editorIcon{	

	height: 12px;
	width: 12px;

	fill: $white;
}

button.slide-choice-media--image-fitImage svg.editorIcon,{
	height: 14px;
	width: 14px;
}

button.slide-choice-media--image-expandImage svg.editorIcon{
	height: 15px;
	width: 15px;
}

button.slide-choice-media--image-removeImage,
button.slide-choice-media--image-expandImage,
button.slide-choice-media--gif-removeGif{
	transition: opacity 50ms linear;
}


// Close "Btn"
.slide-choices .slide-choice-mediaContainer button.slide-choice-media--image-removeImage,
.slide-choices .slide-choice-mediaContainer button.slide-choice-media--gif-removeGif,
.slide-choices .slide-choice-mediaContainer button.slide-choice-media--image-fitImage{
	opacity: 0;	
	//opacity: 1 !important;
	// opacity: 0.5 !important; 
}

.slide-choices:hover .slide-choice-mediaContainer button.slide-choice-media--image-removeImage,
.slide-choices:hover .slide-choice-mediaContainer button.slide-choice-media--gif-removeGif,
.slide-choices:hover .slide-choice-mediaContainer button.slide-choice-media--image-fitImage{
	opacity: 0.15;
}

.slide-choices:hover .slide-choice-mediaContainer:hover button.slide-choice-media--image-removeImage,
.slide-choices:hover .slide-choice-mediaContainer:hover button.slide-choice-media--gif-removeGif,
.slide-choices:hover .slide-choice-mediaContainer:hover button.slide-choice-media--image-fitImage{
	opacity: 0.5;
}

.slide-choices:hover .slide-choice-mediaContainer:hover .slide-choice-media-topRightBtnContainer:hover button.slide-choice-media--image-removeImage,
.slide-choices:hover .slide-choice-mediaContainer:hover .slide-choice-media-topRightBtnContainer:hover button.slide-choice-media--gif-removeGif,
.slide-choices:hover .slide-choice-mediaContainer:hover .slide-choice-media-topLeftBtnContainer:hover button.slide-choice-media--image-fitImage{
	opacity: 0.8;
}

.slide-choices:hover .slide-choice-mediaContainer:hover .slide-choice-media-topRightBtnContainer:hover button.slide-choice-media--image-removeImage:hover,
.slide-choices:hover .slide-choice-mediaContainer:hover .slide-choice-media-topRightBtnContainer:hover button.slide-choice-media--gif-removeGif:hover{
	opacity: 1;
	background: $red;
}

.slide-choices:hover .slide-choice-mediaContainer:hover .slide-choice-media-topRightBtnContainer:hover button.slide-choice-media--image-fitImage:hover{
	opacity: 1;
}

// Fit button
button.slide-choice-media--image-fitImage--active{
	background: $blue;
}


// Expand "Btn"
.slide-choices .slide-choice-mediaContainer button.slide-choice-media--image-expandImage{
	opacity: 0;
	transition: opacity 150ms linear;
}
.slide-choices:hover .slide-choice-mediaContainer:hover button.slide-choice-media--image-expandImage{
	opacity: 0.4;
	transition: opacity 50ms linear;
}

//
// LOADING PLACEHOLDER

.slide-choice-media-topLeftBtnContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 60px;
	height: 60px;

	z-index: 20;

	transform-origin: top left;
	pointer-events: none;
	// background: red;
}

.slide-choice-media-topLeftBtnContainer--fitBtn{
	pointer-events: auto;
	height: 40px;
	width: 40px;
}

.slide-choice-media-topLeftBtnContainer--giphyBadge{
	width: unset;
	height: unset;
	display: flex;

	pointer-events: auto;
}

.slide-choice-media--image-loadingSpinnerContainer,
.slide-choice-media--gif-loadingSpinnerContainer{

	position: absolute;
	top: 6px;
	left: 6px;

	transform-origin: center center;

	width: 24px;
	height: 24px;

	background: none;
	border: none;
	outline: none;
	box-shadow: none;

	background: rgba(0,0,20,0.9);

	opacity: 0.5;

	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}

@keyframes loadingChoiceImageSpinnerRotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}

.slide-choice-media--image-loadingSpinner,
.slide-choice-media--gif-loadingSpinner{
	height: 12px;
	width: 12px;
  
  border-radius: 50%;

  border: 2px rgba(0,0,20,0.9) solid;
  border-top: 2px rgba(255,255,255,1) solid;

  opacity: 1;

  animation: loadingChoiceImageSpinnerRotate 0.6s infinite linear;
}


.slide-choices .slide-choice-mediaContainer .slide-choice-media--image-loadingSpinnerContainer,
.slide-choices .slide-choice-mediaContainer .slide-choice-media--gif-loadingSpinnerContainer{
	opacity: 0;	
	//opacity: 1 !important;
	// opacity: 0.5 !important; 
}

.slide-choices:hover .slide-choice-mediaContainer .slide-choice-media--image-loadingSpinnerContainer,
.slide-choices:hover .slide-choice-mediaContainer .slide-choice-media--gif-loadingSpinnerContainer{
	opacity: 0.15;
}

.slide-choices:hover .slide-choice-mediaContainer:hover .slide-choice-media--image-loadingSpinnerContainer,
.slide-choices:hover .slide-choice-mediaContainer:hover .slide-choice-media--gif-loadingSpinnerContainer{
	opacity: 0.5;
}

// GIPHY BADGE

.slide-choices .slide-choice-mediaContainer  a.slide-mediaContainer--gif-giphyUserLink{
	opacity: 0;
}

.slide-choices:hover .slide-choice-mediaContainer  a.slide-mediaContainer--gif-giphyUserLink{
	opacity: 0;
}

.slide-choices:hover .slide-choice-mediaContainer:hover a.slide-mediaContainer--gif-giphyUserLink{
	opacity: 0.5;
}

.slide-choices:hover .slide-choice-mediaContainer:hover a.slide-mediaContainer--gif-giphyUserLink:hover{
	opacity: 1;
}

//

.slide-choice-media--image-editImageBtnContainer{
	width: 140px;
	height: 36px;

	position: absolute;
	bottom: 0px;

	margin: auto;
	left: 0;
	right: 0;

	transform-origin: center bottom;

}

button.slide-choice-media--image-editImage{
	position: absolute;
	
	width: 100%;

	top: 0px;
	height: 32px;

	background: none;
	border: none;
	outline: none;
	box-shadow: none;

	background: rgba(0,0,20,0.8);

	border-radius: 6px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	color: $white;
	font-size: 14px;
	font-weight: $lato-bold;
	letter-spacing: 0.01em;

	box-sizing: border-box;
	padding-bottom: 2px;

}

button.slide-choice-media--image-editImage svg{
	height: 14px;
	width: 14px;

	position: relative;
	top: 0px;

	fill: $white;
	opacity: 0.9;

	margin-right: 6px;
	margin-left: -3px;
}

.slide-choice-mediaContainer
button.slide-choice-media--image-editImage{
	opacity: 0;
}

.slide-choice-mediaContainer:hover
button.slide-choice-media--image-editImage{
	opacity: 0.7;
}


.slide-choice-mediaContainer:hover
button.slide-choice-media--image-editImage:hover{
	opacity: 1;
}






// not sure if we need this

.slide-choice-media--image-border,
.slide-choice-media--gif-border{
	position: absolute;
	border-radius: 12px;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	border: 1px solid rgba(0,0,20,0.08);
}


//
//
// VIDEO
//
//

.slide-choice-mediaContainer--video{
	// background: $gray-12;
	display: flex;
	flex-direction: column;
}

.slide--static .slide-choice-mediaContainer--video,
.slide--static .slide-choice-mediaContainer--video *{
	// cursor: pointer !important;
	user-select: none !important;
}

.slide-choice-media--video-videoContainer{
	width: 100%;
	min-height: 5px;	
}

.slide-choice-media--video--draggingSeekbar{
	cursor: pointer !important;
}

.slide-choice-media--video--draggingSeekbar
.slide-choice-media--video-videoContainer{
	pointer-events: none !important;
}

.slide-choice-media--video-topLeft{
	height: 30px;
	width: 36px;

	// background: $tempr;

	transform-origin: top left;

	position: absolute;
	top: 0px;
	left: 0px;
}

.slide-choice-media--video-topRight{
	height: 30px;
	width: 36px;

	// background: $tempr;

	transform-origin: top right;

	position: absolute;
	top: 0px;
	right: 0px;

	z-index: 2;
}

.slide-choice-media--video-bottomRight{
	height: 30px;
	width: 36px;

	transform-origin: bottom right;

	position: absolute;
	bottom: 0px;
	right: 0px;

	z-index: 200;
}

.slide-choice-media--video-bottomCenter{
	height: 30px;
	width: 90px;

	transform-origin: bottom center;

	position: absolute;

	margin: auto;
	left: 0px;
	right: 0px;
	bottom: 0px;

	display: flex;
	align-items: center;
	justify-content: center;
	
}



// SHARED WITH VIDEO AND AUDIO

$bgColor-smallBtn-mediaChoices: rgb(244,244,250);

button.slide-choice-media-smallBtn{
	position: absolute;
	top: 6px;

	width: 26px;
	height: 24px;

	background: none;
	border: none;
	outline: none;
	box-shadow: none;

	// background: rgb(250,250,251);
	background: $bgColor-smallBtn-mediaChoices;

	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}

button.slide-choice-media--video-close,
button.slide-choice-media--sound-close,
button.slide-choice-media--video-zoom,
button.slide-choice-media--sound-zoom{
	right: 6px;
}

button.slide-choice-media--sound-replace{
	right: 36px;
}

button.slide-choice-media--video-edit,
button.slide-choice-media--sound-edit{
	left: 6px;
	width: auto;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 14px;
	font-weight: $lato-bold;
	color: $textDark;
}

button.slide-choice-media--video-close svg.editorIcon,
button.slide-choice-media--sound-close svg.editorIcon,
button.slide-choice-media--sound-close svg.editorIcon{	

	height: 12px;
	width: 12px;

	fill: $gray-6;
}

button.slide-choice-media--sound-replace svg.editorIcon{	

	height: 16px;
	width: 16px;

	fill: $gray-6;
}

button.slide-choice-media--video-edit svg.editorIcon,
button.slide-choice-media--sound-edit svg.editorIcon{
	fill: $gray-6;
	width: 15px;
	height: 15px;
}

button.slide-choice-media--video-zoom,
button.slide-choice-media--sound-zoom{
	width: 48px;
	height: 24px;
}

button.slide-choice-media--video-zoom svg.editorIcon,
button.slide-choice-media--sound-zoom svg.editorIcon{
	width: 15px;
	height: 15px;
}




//
.slide-choices .slide-choice-media
button.slide-choice-media-smallBtn{
	opacity: 0;
	//opacity: 1 !important; // dev testing
}

.slide-choices:hover .slide-choice-media
button.slide-choice-media-smallBtn{
	opacity: 0.15;
}

.slide-choices:hover .slide-choice-media:hover
button.slide-choice-media-smallBtn{
	opacity: 0.5;
}

.slide-choices:hover .slide-choice-media:hover
.slide-choice-media--video-topRight:hover
button.slide-choice-media-smallBtn,
.slide-choices:hover .slide-choice-media:hover
.slide-choice-media--video-topLeft:hover
button.slide-choice-media-smallBtn,
.slide-choices:hover .slide-choice-media:hover
.slide-choice-media--sound-topRight:hover
button.slide-choice-media-smallBtn,
.slide-choices:hover .slide-choice-media:hover
.slide-choice-media--sound-topLeft:hover
button.slide-choice-media-smallBtn{
	opacity: 0.5;
}

.slide-choices:hover .slide-choice-media:hover
button.slide-choice-media-smallBtn:hover{
	opacity: 1 !important;
	background: transparentize($blue, 0.9);
	color: $blue;
}

.slide-choices:hover .slide-choice-media:hover
button.slide-choice-media-smallBtn:hover svg{
	fill: $blue;
}


.slide-choices:hover .slide-choice-media:hover button.slide-choice-media--sound-close:hover,
.slide-choices:hover .slide-choice-media:hover button.slide-choice-media--video-close:hover{
	background: transparentize($red, 0.8);
}

.slide-choices:hover .slide-choice-media:hover button.slide-choice-media--sound-close:hover svg,
.slide-choices:hover .slide-choice-media:hover button.slide-choice-media--video-close:hover svg{
	fill: $red;
}


// DARK - USED IN VIDEO
// refactor to use for images as well


button.slide-choice-media-smallBtn--dark{
	background: rgba(0,0,20,0.9);
	color: $white;
}

button.slide-choice-media-smallBtn--dark svg.editorIcon{
	fill: $white;
}

.slide-choices:hover .slide-choice-media:hover button.slide-choice-media-smallBtn--dark:hover{
	background: rgba(0,0,20,0.9);
	color: $white;
}

.slide-choices:hover .slide-choice-media:hover button.slide-choice-media-smallBtn--dark:hover svg{
	fill: $white;
}

// CLOSE BTN

.slide-choices:hover .slide-choice-media:hover button.slide-choice-media-smallBtn--dark.slide-choice-media--video-close:hover{
	background: $red;
}

.slide-choices:hover .slide-choice-media:hover button.slide-choice-media-smallBtn--dark.slide-choice-media--video-close:hover svg{
	fill: $white;
}



// button.slide-choice-media--video-close{
// 	transition: opacity 50ms linear;
// }

// .slide-choices .slide-choice-media
// button.slide-choice-media--image-removeImage{
// 	opacity: 0;
// }

// .slide-choices:hover .slide-choice-media
// button.slide-choice-media--image-removeImage{
// 	opacity: 0.15;
// }

// .slide-choices:hover .slide-choice-media:hover
// button.slide-choice-media--image-removeImage{
// 	opacity: 0.5;
// }

// .slide-choices:hover .slide-choice-media:hover
// .slide-choice-media-topRightBtnContainer:hover
// button.slide-choice-media--image-removeImage{
// 	opacity: 0.8;
// }

// .slide-choices:hover .slide-choice-media:hover
// .slide-choice-media-topRightBtnContainer:hover
// button.slide-choice-media--image-removeImage:hover{
// 	opacity: 1;
// }


//
//

button.slide-choice-media--video-expandVideo{
	position: absolute;	
	left: 0px;
	top: 0px;

	width: 36px;
	height: 30px;

	background: none;
	border: none;
	outline: none;
	box-shadow: none;


	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}


button.slide-choice-media--video-expandVideo svg.editorIcon{	

	transform: scale(0.6); // can't get to resize
	transform-origin: center center;

	fill: $gray-5;
	opacity: 0.6;

	position: relative;
	top: -2px;

	transition: all 100ms linear;
}

button.slide-choice-media--video-expandVideo:hover svg.editorIcon{
	transform: scale(0.7);
	opacity: 1;
}

.slide-choices .slide-choice-media--video
button.slide-choice-media--video-expandVideo{
	opacity: 0.25;
}

.slide-choices:hover .slide-choice-media--video
button.slide-choice-media--video-expandVideo{
	opacity: 0.8;
}

.slide-choices:hover .slide-choice-media--video:hover
button.slide-choice-media--video-expandVideo{
	opacity: 1;
}


// TIMESTAMPS
.slide-choice-media--video-bottomCenter-timestampContainer{
	
	display: flex;
	justify-content: center;
	align-items: center;

	cursor: default;
	user-select: none;
}

.slide-choice-media--video-bottomCenter-timestamp{
	font-size: 15px;
	font-weight: $lato-semibold;
	color: $textBlackDark;
}

.slide-choice-media--video-bottomCenter-timestamp--pausedTotalTime{
	opacity: 0.5;
}

.slide-choice-media--video:hover
.slide-choice-media--video-bottomCenter-timestamp--pausedTotalTime{
	opacity: 0.75;
}

.slide-choice-media--video-bottomCenter-timestamp--currentTime,
.slide-choice-media--video-bottomCenter-timestamp--totalTime{
	width: 32px;
}

.slide-choice-media--video-bottomCenter-timestamp--dash{
	width: 10px;
}

.slide-choice-media--video-bottomCenter-timestamp--dash,
.slide-choice-media--video-bottomCenter-timestamp--totalTime{
	opacity: 0.5;
}

//
// VIDEO VIDEO


.slide-choice-media--video-videoContainer{
	
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	overflow: hidden;
}



// hack


iframe.slide-choice-media--video-video{
	transform-origin: top left;
	position: absolute;

}

.slide-media-choice--video-controlBarContainer{
	position: absolute;
	// bottom: 0px;
	bottom: 0px;
	left: 0px;

	z-index: 100;

	transform-origin: bottom left;

	// background: $tempr;
}

.slide--editor .slide-media-choice--video-controlBarContainer{
	pointer-events: none;
}


// VIDEO CONTROL BAR

@import 'slide-Choice-Media-VideoControlBar'; // media choices


// STATIC VIDEO STATIC

.slide-choice-media--video--static-thumbnailContainer{
	position: absolute;
	right: 0px;
	top: 0px;	
	background: $white;
	
	overflow: hidden;
	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.12), 0px 0px 0px 1px rgba(0,0,20,0.12);

	pointer-events: none;
}

// THUMBNAIL


img.slide-choice-media--video--static-thumbnail{	
  user-select: none;
  height: 100%;
  width: 100%;
  object-fit: cover;
  
  // pointer-events: none;

  // opacity: 0;
}


.slide-choice-media--video--static
.slide-choice-media--video-topLeft{
	height: 48px;
	width: 48px;
	z-index: 10;
}

.slide-choice-media--video--static-videoIconContainer{
	height: 100%;
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0.75;
}

.slide-choice-media--video--static-videoIconContainer svg.editorIcon{
	width: 24px;
	height: 24px;	
}

.slide-choice-media--video--static-videoIconContainer svg.editorIcon #bg{
	fill: #FF2323; // red
}

.slide-choice-media--video--static-videoIconContainer svg.editorIcon #play{
	fill: $white;
}


.slide-choice-media--video--static-videoIconContainer--overlay svg.editorIcon #bg{
	fill: $black;
}

.slide-choice-media--video--static-videoIconContainer--overlay svg.editorIcon #play{
	fill: $white;
}

.slide-choice-media--video--static
.slide-media-choice--video-controlBar-initialTitleAndTimestamp-title{
	font-size: 18px;
	margin-bottom: 1px;
	letter-spacing: 0.03em;
	color: $black;
	cursor: default;
}

.slide-choice-media--video--static
.slide-media-choice--video-controlBar-timestamp--overlayMode
.slide-media-choice--video-controlBar-initialTitleAndTimestamp-title{
	color: $white;
}

.slide-choice-media--video--static
.slide-media-choice--video-controlBar-initialTitleAndTimestamp-timestamp{
	font-size: 16.5px;
	font-weight: $lato-bold;
	color: lighten($textMid, 5%);
	letter-spacing: 0.03em;
}

.slide-choice-media--video--static
.slide-media-choice--video-controlBar-timestamp--overlayMode
.slide-media-choice--video-controlBar-initialTitleAndTimestamp-timestamp{
	color: $white;
	opacity: 0.8;
}

// EDITOR STATE

.slide-media-choice--video-controlBar-timestamp--overlayMode
.slide-media-choice--video-controlBar-initialTitleAndTimestamp{
	background: rgba(0,0,5,0.75);
}


.slide--editor
.slide-choices
.slide-choice-mediaContainer
.slide-media-choice--video-controlBar-timestamp--overlayMode{
	opacity: 0.7;
}

.slide--editor
.slide-choices:hover
.slide-choice-mediaContainer
.slide-media-choice--video-controlBar-timestamp--overlayMode{
	opacity: 0.9;
}

.slide--editor
.slide-choices:hover
.slide-choice-mediaContainer:hover
.slide-media-choice--video-controlBar-timestamp--overlayMode{
	opacity: 1;
}


//
// SOUND
// PLAYER and STATIC
//



.slide-choice-media--sound-topLeft{
	height: 30px;
	width: 36px;

	transform-origin: top left;

	position: absolute;
	top: 0px;
	left: 0px;
}

.slide-choice-media--sound-topRight{
	height: 30px;
	width: 36px;

	// background: $tempr;

	transform-origin: top right;

	position: absolute;
	top: 0px;
	right: 0px;
}

.slide-choice-media--sound-center{
	height: 90px;
	width: 90px;

	transform-origin: center;

	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;

	margin: auto;

	z-index: 10;
}

// if editor, then offset play button a little bit

.slide-choice-media--sound--editor
button.slide-choice-media--sound-playPauseBtn{
	margin-top: -6%;
}

.slide-choice-media--sound--static
.slide-choice-media--sound-center{
	transform: scale(1.55);
}

.slide-choice-media--sound-bottom{
	font-size: 12px;

	// background: $tempr;

	position: absolute;
	bottom: 0px;

	transform-origin: bottom left;

	display: flex;
	align-items: center;
	justify-content: center;	
}



button.slide-choice-media--sound-playPauseBtn{
	width: 100%;
	height: 100%;

	background: none;
	border: none;
	box-shadow: none;

	background: transparentize($blue, 0.9);
	background: #E9F0FF; 
	background: #EBF4FE;
	// background: white;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 50%;

	cursor: pointer;
}




button.slide-choice-media--sound-playPauseBtn:disabled{
	cursor: default; // static
	pointer-events: none;
	background: $gray-15; // maybe, test properly later
}

button.slide-choice-media--sound-playPauseBtn:hover{
	// background: transparentize($blue, 0.9);
	// background: #e0edfc;
	background: darken(#EBF4FE, 1.5%);
}

button.slide-choice-media--sound-playPauseBtn:focus{
	outline: none;
}

button.slide-choice-media--sound-playPauseBtn:active{	
	background: darken(#EBF4FE, 1.5%);
}

//

button.slide-choice-media--sound-playPauseBtn svg{
	fill: $blue;
}

button.slide-choice-media--sound-playPauseBtn svg.editorIcon-playbackPlay{
	// transform: scale(0.85);	
	width: 32px;
	height: 32px;
	position: relative;
	right: -2px;
}

button.slide-choice-media--sound-playPauseBtn svg.editorIcon-insertSound{
	//transform: scale(1.1);	
	width: 40px;
	height: 40px;
}

button.slide-choice-media--sound-playPauseBtn svg.editorIcon-playbackPause{
	width: 34px;
	height: 34px;
}


button.slide-choice-media--sound-playPauseBtn svg.editorIcon-insertSound{
	display: none;
}



.slide-choice-media--sound--static
button.slide-choice-media--sound-playPauseBtn svg.editorIcon-insertSound{
	display: block; // static
}

.slide-choice-media--sound--initial
button.slide-choice-media--sound-playPauseBtn svg.editorIcon-insertSound{
	display: block;
}

button.slide-choice-media--sound-playPauseBtn:hover svg.editorIcon-insertSound{
	display: none;
}

.slide-choice-media--sound--initial
button.slide-choice-media--sound-playPauseBtn svg.editorIcon-playbackPlay{
	display: none;
}

.slide-choice-media--sound--initial
button.slide-choice-media--sound-playPauseBtn:hover svg.editorIcon-playbackPlay{
	display: block;
}

// BOTTOM TIMESTAMP

// temp dev
// .slide-choice-mediaContainer--sound{
// 	overflow: unset;
// }

.slide-choice-media--sound-bottom-timestampContainer{
	// position: absolute;
	// height: 48px;
	// left: 0px;
	// right: 0px;
	// top: 0px;

	min-width: 10px;
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	user-select: none;
	cursor: default;

	// background: $tempb;

	box-sizing: border-box;
	
	padding-left: 20px;
	padding-right: 20px;
	
}

// .slide-choice-media--sound--static
// .slide-choice-media--sound-bottom-timestampContainer{
// 	transform: scale(1.5);
// 	transform-origin: bottom center;
// }
// .slide-choice-media--sound--static
// .slide-choice-media--sound-bottom-timestampContainer{
// 	bottom: 24px;	
// }

.slide-choice-media--sound-initialTitleAndTimestamp{
	
	// width: 300px
	// background: $tempr;
}

.slide-choice-media--sound-initialTitleAndTimestamp-title{
	width: 100%;
	box-sizing: border-box;
	text-align: center;

	font-size: 14.5px;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.02em;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  

  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;

  border-radius: 4px;

  
  // background: $tempr;

  margin-bottom: -2px;
}

.slide--editor
.slide-choice-media--sound-initialTitleAndTimestamp-title{
	cursor: pointer;	
}

.slide--editor
.slide-choice-media--sound-initialTitleAndTimestamp-title:hover{
	background: transparentize($bgColor-smallBtn-mediaChoices, 0.45);
	// color: $blue;
}

.slide-choice-media--sound-initialTitleAndTimestamp-timestamp{
	width: 100%;
	text-align: center;

	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textLight;

	// background: $tempr;
}


// static static
.slide-choice-media--sound--static
.slide-choice-media--sound-initialTitleAndTimestamp-title{
	font-size: 18px;
	margin-bottom: 1px;
	letter-spacing: 0.03em;
	color: $black;
}

.slide-choice-media--sound--static
.slide-choice-media--sound-initialTitleAndTimestamp-timestamp{
	font-size: 16.5px;
	font-weight: $lato-bold;
	color: lighten($textMid, 5%);
	letter-spacing: 0.03em;
}


// .slide-choice-media--sound-timestamp{
// 	font-size: 16px;
// 	font-weight: $lato-bold;
// 	color: $textDark;
// 	letter-spacing: 0.01em;

// 	margin-top: 16px;

// 	width: 100%;
// 	text-align: center;
// }



// .slide-choice-media--sound--static
// .slide-choice-media--sound-timestamp{
// 	font-size: 22px;
// }


// .slide-choice-media--sound-bottom--notPlaying
// .slide-choice-media--sound-bottom-timestampContainer{
// 	visibility: visible;
// }

// .slide-choice-media--sound-bottom--notPlaying
// .slide-choice-media--sound-bottom-seekBarContainer{
// 	visibility: hidden;
// }

// .slide-choice-media--sound-bottom--notPlaying:hover
// .slide-choice-media--sound-bottom-timestampContainer{
// 	visibility: hidden;
// }

// .slide-choice-media--sound-bottom--notPlaying:hover
// .slide-choice-media--sound-bottom-seekBarContainer{
// 	visibility: visible;
// }


// .slide-choice-media--sound-bottom--playing
// .slide-choice-media--sound-bottom-timestampContainer{
// 	visibility: hidden;
// }

// .slide-choice-media--sound-bottom--playing
// .slide-choice-media--sound-bottom-seekBarContainer{
// 	visibility: visible;
// }

// .slide-choice-media--sound-bottom--playing:hover
// .slide-choice-media--sound-bottom-timestampContainer{
// 	visibility: hidden;
// }

// .slide-choice-media--sound-bottom:hover
// .slide-choice-media--sound-bottom-seekBarContainer{
// 	visibility: visible;
// }


//

.slide-choice-media--sound-bottom-seekBarContainer{
	position: absolute;
	top: 8px;
	bottom: 2px;
	// background: $tempr;
}

.slide-choice-media--sound-bottom-seekBarContainer--choiceCount--4{
	left: 20px;
	right: 20px;
}

.slide-choice-media--sound-bottom-seekBarContainer--choiceCount--3{
	left: 30px;
	right: 30px;
}
.slide-choice-media--sound-bottom-seekBarContainer--choiceCount--2{
	left: 50px;
	right: 50px;
}

.slide-choice-media--sound-bottom-seekBarContainer .rc-slider{
	height: 100%;
	cursor: pointer;

	// padding-top: 28px;
}

.slide-choice-media--sound-bottom-seekBarContainer
.rc-slider-handle{
	width: 15px;
	height: 15px;
	margin-top: -6px;
	// border-radius: 50%;
	background: $blue;
	border: 2px solid white;
	//box-shadow: 0px 0px 0px 2px rgba(0,0,10,0.04);
	cursor: pointer;

	// position: absolute;
	// top: 3px;
	// left: 25%;

	top: 40%;
}

.slide-choice-media--sound-bottom-seekBarContainer .rc-slider-handle:hover,
.slide-choice-media--sound-bottom-seekBarContainer .rc-slider-handle:focus,
.slide-choice-media--sound-bottom-seekBarContainer .rc-slider-handle:active{
	border: 2px solid white;
	
	cursor: pointer;
	box-shadow: none;

	// box-shadow: 0px 0px 0px 2px rgba(0,0,10,0.04);

}


.slide-choice-media--sound-bottom-seekBarContainer
.rc-slider-track{
	background: $blue;
	height: 3px;
	top: 40%;
}

.slide-choice-media--sound-bottom-seekBarContainer
.rc-slider-rail{	
	background: transparentize($blue, 0.9);
	height: 3px;
	top: 40%;
}

// VISUALIZER TEST

.slide-choice-media--sound-visualizerContainer{
	height: 150px;
	width: 150px;

	transform-origin: center;

	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;

	margin: auto;

	// background: $tempb;

	z-index: 0;
}

.slide-choice-media--sound-visualizer{
	height: 75px;
	width: 75px;
	border-radius: 50%;

	transform-origin: center;

	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;

	margin: auto;

	background: transparentize($blue, 0.95);

	
}

//
// .slide-media--sound-player-waveformImageContainer{
// 	position: absolute;
// 	bottom: 10px;
// 	top: 11px;
// 	left: 0px;
// 	right: 0px;

// 	// background: $tempb;
// }

// .slide-media--sound-player-waveformImageContainer img{
// 	width: 100%;
// 	height: 100%;
// 	object-fit: fill;

// 	opacity: 0.2;
// }



///
/// BLANK

//
// CHOICE MEDIA
//


// MAY NEED TO REMOVE FOR INSERT SOUND
// .slide-choices:hover
// .slide-choice-mediaContainer--blank{
// 	// opacity: 0.8;
// }


.slide-choice-mediaContainer--blank{
	padding: 14px;
	padding-bottom: 4px;
	padding-top: 24px;
	// background: $blue;
}

.slide-choice-mediaContainer--blank-insertSoundModalContainer{
	position: absolute;
	margin: auto;

	bottom: 10px;
	
	// background: $tempr;
	z-index: 20;

	width: 380px;
	height: 232px;

	transform-origin: bottom center;

}

// .slide-choice:first-child
// .slide-choice-mediaContainer--blank-insertSoundModalContainer{
// 	// background: $tempg;
// 	// margin-left: 40%;
// }

// .slide-choice:last-child
// .slide-choice-mediaContainer--blank-insertSoundModalContainer{
// 	// background: $tempg;
// 	margin-left: -60%;
// }


.slide-choice-media--blankInnerContainer:hover{
	background: transparentize($blue, 0.9);
}

.slide-choice-media--blankInnerContainer{
	height: 100%;
	width: 100%;
	box-sizing: border-box;

	background: rgb(250,250,251);

	border-radius: 14px;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;

	padding: 10px;
}

// TEST FOR PRE-HOVER PLUS BUTTON
// .slide-choice-media--blank-preHoverPlus{
// 	position: absolute;
// 	top: 20px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	z-index: 999;

// 	// background: $tempr;
// 	visibility: hidden;
// }

// .slide-choice-media--blank-preHoverPlus svg{
// 	width: 44px;
// 	height: 44px;

// 	fill: $gray-11;
// }

// .slide-choice:nth-child(n+1) .slide-choice-mediaContainer--blank button.slide-choice-media--blank-insertImageBtn,
// .slide-choice:nth-child(n+1) .slide-choice-mediaContainer--blank .slide-choice-media--blank-bottomBtnRow{
// 	visibility: hidden;
// }

// .slide-choice:nth-child(n+2) .slide-choice-mediaContainer--blank:hover button.slide-choice-media--blank-insertImageBtn,
// .slide-choice:nth-child(n+2) .slide-choice-mediaContainer--blank:hover .slide-choice-media--blank-bottomBtnRow{
// 	visibility: visible;
// }

// .slide-choice:nth-child(n+2) .slide-choice-mediaContainer--blank
// .slide-choice-media--blank-preHoverPlus{
// 	visibility: visible;
// }

// .slide-choice:nth-child(n+2) .slide-choice-mediaContainer--blank:hover
// .slide-choice-media--blank-preHoverPlus{
// 	visibility: hidden;
// }



button.slide-choice-media--blank-insertImageBtn{
	flex-grow: 1;
	flex-shrink: 1;
}

.slide-choice-media--blank-bottomBtnRow{
	display: flex;
	height: 36px;

	flex-grow: 0;
	flex-shrink: 0;
}

.slide-choice-media--blank-bottomBtn{
	width: 50%;
}

button.slide-choice-media--blank-insertImageBtn,
button.slide-choice-media--blank-bottomBtn{
	border: none;
	border-radius: 10px;
	box-shadow: none;

	background: none;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	// background: $tempr;
}

button.slide-choice-media--blank-insertImageBtn:hover,
button.slide-choice-media--blank-bottomBtn:hover{
	background: transparentize($blue, 0.9);
}

button.slide-choice-media--blank-bottomBtn:disabled{
	pointer-events: none;
}

button.slide-choice-media--blank-insertImageBtn:focus,
button.slide-choice-media--blank-bottomBtn:focus,
button.slide-choice-media--blank-insertImageBtn:active,
button.slide-choice-media--blank-bottomBtn:active{
	background: transparentize($blue, 0.8);
	border: none;
	outline: none;
}


// ICON COLORING

button.slide-choice-media--blank-insertImageBtn svg{
	fill: $gray-10;
}

button.slide-choice-media--blank-bottomBtn svg{
	fill: $gray-10;
}

.slide-choice-media--blankInnerContainer:hover button.slide-choice-media--blank-insertImageBtn svg,
.slide-choice-media--blankInnerContainer:hover button.slide-choice-media--blank-bottomBtn svg{
	fill: $blue;
}


button.slide-choice-media--blank-insertImageBtn:focus svg,
button.slide-choice-media--blank-bottomBtn:focus svg,
button.slide-choice-media--blank-insertImageBtn:active svg,
button.slide-choice-media--blank-bottomBtn:active svg{
	fill: $blue;
}


// ICON SIZING

button.slide-choice-media--blank-insertImageBtn svg{
	width: 70px;
	height: 70px;

	position: relative;
	top: 4px;
}

button.slide-choice-media--blank-bottomBtn
.slide-choice-media--blank-bottomBtn-iconContainer{
	transform-origin: center center;
	// background: $tempr;
}


button.slide-choice-media--blank-bottomBtn--insertVideo
.slide-choice-media--blank-bottomBtn-iconContainer{	
	width: 30px;
	height: 30px;

}

button.slide-choice-media--blank-bottomBtn--insertSound
.slide-choice-media--blank-bottomBtn-iconContainer{	
	width: 24px;
	height: 24px;	

}

// button.slide-choice-media--blank-bottomBtn--insertClipboard
// .slide-choice-media--blank-bottomBtn-iconContainer{	
// 	width: 18px;
// 	height: 18px;	
// }

button.slide-choice-media--blank-bottomBtn--insertVideo
.slide-choice-media--blank-bottomBtn-iconContainer svg{
	width: 30px;
	height: 30px;

	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
}

button.slide-choice-media--blank-bottomBtn--insertSound
.slide-choice-media--blank-bottomBtn-iconContainer svg{
	width: 24px;
	height: 24px;

	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
}



// 

.slide-choice-media--blankInnerContainer:hover button.slide-choice-media--blank-insertImageBtn,
.slide-choice-media--blankInnerContainer:hover button.slide-choice-media--blank-bottomBtn{
	opacity: 0.5;
}

.slide-choice-media--blankInnerContainer:hover button.slide-choice-media--blank-insertImageBtn:hover,
.slide-choice-media--blankInnerContainer:hover button.slide-choice-media--blank-bottomBtn:hover{
	opacity: 1;
}

.slide-choice-media--blankInnerContainer:hover button.slide-choice-media--blank-bottomBtn:disabled{
	opacity: 0.25;
}

button.slide-choice-media--blank-bottomBtn:disabled svg{
	opacity: 0.5;
}


//
// REMOVE CHOICE BUTTON

.slide-choice-media--blank-deleteChoice{
	position: absolute;
	height: 30px;
	width: 30px;

	top: 10px;
	right: 0px;

	transform-origin: top right;

	z-index: 10;

	display: flex;
	align-items: center;
	justify-content: center;

	border: 3px solid $white;
	background: $gray-15;

	border-radius: 6px;

	cursor: pointer;
}

.slide-choice-media--blank-deleteChoice svg{
	
	width: 12px;
	height: 12px;

	fill: $gray-12;
}

.slide-choice-media--blank-deleteChoice{
	opacity: 0;
	opacity: 1;
}

.slide:hover
.slide-choice-media--blank-deleteChoice{
	opacity: 1;
}

.slide-choice-mediaContainer--blank:hover
.slide-choice-media--blank-deleteChoice{
	background: $gray-14;
}

.slide-choice-mediaContainer--blank:hover
.slide-choice-media--blank-deleteChoice svg{
	fill: $gray-8;
}

.slide-choice-mediaContainer--blank:hover
.slide-choice-media--blank-deleteChoice:hover{
	background: $red;
	background: #FDE9EA;
}

.slide-choice-mediaContainer--blank:hover
.slide-choice-media--blank-deleteChoice:hover svg{
	fill: $white;
	fill: $red;
}

.slide-choice--choiceCount--2
.slide-choice-media--blank-deleteChoice{
	display: none;
}
