$choiceVideoControls-seekRed: #FF2323;

.slide-media-choice--video-controlBar{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	// background: $tempr;
}



// Timestamps
.slide-media-choice--video-controlBar-timestamp{
	position: absolute;
	bottom: 0px;
	// top: 12px;
	top: 0px;
	left: 0px;
	right: 0px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: default;
	user-select: none;
}

.slide-media-choice--video-controlBar-initialTitleAndTimestamp{
	max-width: calc(100% - 8px);
}

.slide-media-choice--video-controlBar-playingTimestamps,
.slide-media-choice--video-controlBar-initialTitleAndTimestamp{
	// position: absolute;
	// left: 6px;
	// right: 6px;
	// top: 2px;
	// bottom: 0px;

	box-sizing: border-box;
	// margin-top: -2px;

	padding-left: 8px;
	padding-right: 8px;
	padding-top: 3px;
	padding-bottom: 5px;

	border-radius: 5px;

	// background: $tempr	
}

.slide-media-choice--video-controlBar-playingTimestamps{
	font-weight: $lato-bold;
	color: $textBlack;
	font-size: 14px;
	letter-spacing: 0.02em;
}

.slide-editor
.slide-media-choice--video-controlBar-initialTitleAndTimestamp{
	cursor: pointer;
}

.slide-media-choice--video-controlBar-initialTitleAndTimestamp-title{
	width: 100%;
	box-sizing: border-box;
	text-align: center;

	font-size: 14.5px;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.02em;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  

  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;

  border-radius: 4px;

  margin-bottom: -2px;
}


.slide-media-choice--video-controlBar-initialTitleAndTimestamp-timestamp{
	width: 100%;
	text-align: center;

	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textLight;

	// background: $tempr;
}

.slide-media-choice--video-controlBar-timestamp--overlayMode
.slide-media-choice--video-controlBar-initialTitleAndTimestamp,
.slide-media-choice--video-controlBar-timestamp--overlayMode
.slide-media-choice--video-controlBar-playingTimestamps{
	background: rgba(0,0,20,0.9);
	margin-top: -20px;
}

.slide-media-choice--video-controlBar-timestamp--overlayMode .slide-media-choice--video-controlBar-initialTitleAndTimestamp-title,
.slide-media-choice--video-controlBar-timestamp--overlayMode .slide-media-choice--video-controlBar-playingTimestamps{
	color: $white;
}

.slide-media-choice--video-controlBar-timestamp--overlayMode
.slide-media-choice--video-controlBar-initialTitleAndTimestamp-timestamp{
	color: $white;
	opacity: 0.75;
}


.slide-media-choice--video-controlBar-timestamp-current,
.slide-media-choice--video-controlBar-timestamp-total,
.slide-media-choice--video-controlBar-timestamp-totalPaused,
.slide-media-choice--video-controlBar-timestamp-dash{
	font-size: 15px;
	font-weight: $lato-bold;
	color: $textDark;
	letter-spacing: 0.01em;
}

.slide-media-choice--video-controlBar-timestamp-dash{
	// background: $tempb;
	width: 8px;
}

.slide-media-choice--video-controlBar-timestamp-current,
.slide-media-choice--video-controlBar-timestamp-total{
	width: 38px;
	// background: $tempr;

	text-align: center;
}

.slide-media-choice--video-controlBar-timestamp-total,
.slide-media-choice--video-controlBar-timestamp-totalPaused{
	opacity: 0.5;
}




//
// SEEK BAR

.slide-media-choice--video-controlBar-seekBar{
	position: absolute;
	top: -15px;
	height: 30px;
	left: 0px;
	right: 0px;

	z-index: 10;
	cursor: pointer;

	// background: $tempr;
}

.slide-media-choice--video-controlBar-seekBar
.rc-slider{
	height: 30px;
}


.slide-media-choice--video-controlBar-seekBar
.rc-slider-handle{
	display: none;	
}

.slide-media-choice--video-controlBar-seekBar
.rc-slider-track{
	background: $choiceVideoControls-seekRed;
	height: 3px;
	margin-top: 10px;
	border-radius: 0px;
}

.slide-media-choice--video-controlBar-seekBar
.rc-slider-rail{	
	background:none;
	margin-top: 10px;
	height: 3px;
	border-radius: 0px;
}

.slide-media-choice--video-controlBar-seekBar--overlayMode{
	top: 17px;
	height: 19px;
}

.slide-choice--choiceCount--2
.slide-media-choice--video-controlBar-seekBar{

}


// TOOLTIP

.slide-media-choice--video-controlBar-seekBar-tooltip {
  display: none;
}

//
// WHEN STATIC OVERLAY MODE, HIDE TIMESTAMP IF NOT HOVERING

.slide-media-choice--video-controlBar-timestamp--overlayMode
.slide-media-choice--video-controlBar-playingTimestamps{
	transition: opacity 300ms linear;
	transition-delay: 600ms;
	opacity: 0;
}

.slide-choice-mediaContainer--video:hover
.slide-media-choice--video-controlBar-timestamp--overlayMode
.slide-media-choice--video-controlBar-playingTimestamps{
	transition: opacity 25ms linear;
	opacity: 1;
}