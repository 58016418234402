.nowPlaying--emptyState{
	background: $gray-14;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding-bottom: 60px;

	user-select: none;
}


.nowPlaying--emptyState-block{
	text-align:center;
}



.nowPlaying--emptyState-block-title{
	font-size: 23px;
	font-weight: $lato-heavy;
	color: $textMid;
	letter-spacing: 0.005em;

	margin-bottom: 8px;
	
}


.nowPlaying--emptyState-block-time{
	font-size: 18px;
	font-weight: $lato-bold;
	color: $textLight;
	letter-spacing: 0.01em;
	opacity: 0.7;
	user-select: none;
	transition: opacity 100ms linear;
}


.nowPlaying--emptyState .nowPlaying-home{
	opacity: 0.8;
}

.nowPlaying--emptyState-block-title--sectionTitle--blue{
	color: $classColor-blue;
}

.nowPlaying--emptyState-block-title--sectionTitle--purple{
	color: $classColor-purple;
}

.nowPlaying--emptyState-block-title--sectionTitle---red{
	color: $classColor-red;
}

.nowPlaying--emptyState-block-title--sectionTitle--orange{
	color: $classColor-orange;
}

.nowPlaying--emptyState-block-title--sectionTitle--green{
	color: $classColor-green;
}

.nowPlaying--emptyState-block-title--sectionTitle--sky{
	color: $classColor-sky;
}

.nowPlaying--emptyState-block-title--sectionTitle--pink{
	color: $classColor-pink;
}

.nowPlaying--emptyState-block-title--sectionTitle--teal{
	color: $classColor-teal;
}

.nowPlaying--emptyState-block-title--sectionTitle--grey{
	color: $classColor-grey;
}