

$borderRadius-sectionCard: 5px;

$height-sectionCard: 250px;

.homePage-sectionCardContainer{
	width: 220px;
	height: $height-sectionCard;
	
	// overflow: hidden;

	margin: 10px;

	// background: $tempr;

	// transform: scale(1);
	// transition: all 200ms cubic-bezier(.2, .3, .25, .9);	

	position: relative;
}

.homePage-sectionCardContainer--failure,
.homePage-sectionCardContainer--failure .homePage-sectionCard{
	cursor: default;
}

//

.homePage-sectionCard{
	border-radius: $borderRadius-sectionCard;
	box-shadow: 0px 2px 6px 0px rgba(0,0,40,0.07);
	height: 100%;
	width: 100%;
	overflow: hidden;
	transform: all 200ms linear;
	//background: red;
	background: white;
	border: 1px solid $lineMid;
	cursor: pointer;
	position: relative;
	z-index: 10;
}

.homePage-sectionCardContainer.homePage-sectionCardContainer--dontShowDeauthButtons:hover{
	transform: scale(1.03);	
	transition: transform 150ms cubic-bezier(.2, .3, .25, .9);	
}

.homePage-sectionCardContainer.homePage-sectionCardContainer--dontShowDeauthButtons:hover
.homePage-sectionCard{
	transform: all 200ms linear;
	box-shadow: 0px 6px 12px 0px rgba(0,0,40,0.05);
}



.homePage-sectionCardContainer.homePage-sectionCardContainer--dontShowDeauthButtons{	
	transition: transform 200ms cubic-bezier(.2, .3, .25, .9);
}

@import 'homePage-sectionCard-registerBar'; // progress bar when loading class

.homePage-sectionCard-top{
	height: 200px;
	width: 100%;
	background: $blue;

	border-top-left-radius: $borderRadius-sectionCard;
	border-top-right-radius: $borderRadius-sectionCard;

	box-sizing: border-box;
	padding-left: 15px;
	padding-top: 20px;

	cursor: pointer;
	user-select: none;
}

.homePage-sectionCard-top-sectionName{
	font-size: 20px;
	font-weight: $lato-heavy;
	color: $white;
	letter-spacing: 0.02em;

	margin-bottom: 2px;
}

.homePage-sectionCard-top-teacherName{
	font-size: 15px;
	font-weight: $lato-semibold;
	letter-spacing: 0.01em;
	color: $white;
	opacity: 0.8;

	margin-bottom: 6px;
}

.homePage-sectionCard-top-studentName{
	font-size: 15px;
	font-weight: $lato-semibold;
	letter-spacing: 0.01em;
	color: $white;
	opacity: 0.6;
}

.homePage-sectionCard-bottom{
	height: 30px;
	bottom: 0px;
	width: 100%;
}

@import 'homePage-sectionCard-statusAndCountdown';
// now playing, offline, and countdown timer






//

@import 'homePage-sectionCard-errorMessage'; // failure to join error alert
@import 'homePage-sectionCard-logOutButton'; // failure to join error alert

//


.homePage-sectionCard--color--blue .homePage-sectionCard-top{
	background: radial-gradient(at 25% 25%, $classColor-blue, darken($classColor-blue, 8%));
}

.homePage-sectionCard--color--purple .homePage-sectionCard-top{
	background: radial-gradient(at 25% 25%, $classColor-purple, darken($classColor-purple, 8%));
}

.homePage-sectionCard--color--red .homePage-sectionCard-top{
	background: radial-gradient(at 25% 25%, $classColor-red, darken($classColor-red, 8%));
}

.homePage-sectionCard--color--orange .homePage-sectionCard-top{
	background: radial-gradient(at 25% 25%, $classColor-orange, darken($classColor-orange, 8%));
}

.homePage-sectionCard--color--green .homePage-sectionCard-top{
	background: radial-gradient(at 25% 25%, $classColor-green, darken($classColor-green, 8%));
}

.homePage-sectionCard--color--sky .homePage-sectionCard-top{
	background: radial-gradient(at 25% 25%, $classColor-sky, darken($classColor-sky, 8%));
}

.homePage-sectionCard--color--pink .homePage-sectionCard-top{
	background: radial-gradient(at 25% 25%, $classColor-pink, darken($classColor-pink, 8%));
}

.homePage-sectionCard--color--teal .homePage-sectionCard-top{
	background: radial-gradient(at 25% 25%, $classColor-teal, darken($classColor-teal, 8%));
}

.homePage-sectionCard--color--grey .homePage-sectionCard-top{
	background: radial-gradient(at 25% 25%, $classColor-grey, darken($classColor-grey, 8%));
}
