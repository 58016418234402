$selectionBlue: rgba(0,116,255,0.29);
$equationSelectionBlueOpaque: #BFD7FF;
$hoverEquationBG: lighten(#DEEAFF, 3%);



//
// KATEX TWEAKS
//

.ProseMirror-selectednode{
  outline: none !important;
}

.katexOuterContainer{
  display: inline-block;
  margin: 0;
  position: relative;
  text-align: center;
  white-space: nowrap; // breaks ghost horizontal stuff
}


.katexInnerContainer{
  border: solid 1px transparent;
  display: inline-block;
  padding: 0 2px;
  user-select: none;
}

.katexOuterContainer.ProseMirror-selectednode .katexInnerContainer{
  background: $equationSelectionBlueOpaque;
}

.katexInnerContainer-imageSpacer{
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.katexInnerContainer-content{
  pointer-events: none;
}

.katexInnerContainer-content .katex {
  font-display: block;
}

.slide.slide--editor .katexOuterContainer{
  cursor: pointer;
}

.slide.slide--editor .katexOuterContainer:hover:not(.ProseMirror-selectednode){
  background: $hoverEquationBG;
  box-shadow: 0px 0px 0px 3px $hoverEquationBG;
}

.slide.slide--editor .katexOuterContainer:active:not(.ProseMirror-selectednode){
  background: darken($hoverEquationBG, 5%);
  box-shadow: 0px 0px 0px 3px darken($hoverEquationBG, 5%);
}


.katex-container{
  white-space: nowrap; //prevents wrapping
}

// .slide.slide--editor .katex{ 
//   cursor: pointer;
// }

// .slide .katex .base,
// .fontSizeBodyArray .katex .base,
// .fontSizeChoicesArray .katex .base{
//   border: 4px solid $invisible;
// }

// .slide.slide--editor .katex:hover .base{
//   background: $hoverEquationBG;
//   border: 4px solid $invisible;  
//   cursor: pointer;
//   border-radius: 5px;
// }



// .slide.slide--editor .slide-body .katex:hover .base{
//   
//   // box-shadow: 0px 0px 0px 3px $equationSelectionBlueOpaque;
// }

// .slide.slide--editor span.katex-container.katex-container--selected .katex:hover .base{
//   box-shadow: 0px 0px 0px 3px $equationSelectionBlueOpaque;
// }


// .slide.slide--editor .slide-body.slide-body--focused span.katex-container.katex-container--selected .katex .base,
// .slide.slide--editor .slide-choice.slide-choice--focused span.katex-container.katex-container--selected .katex .base{
//   background: $equationSelectionBlueOpaque !important;
//   //outline: 4px solid $tempb;
//   //box-shadow: -4px 0px 0px 0px $equationSelectionBlueOpaque, 4px 0px 0px 0px $equationSelectionBlueOpaque;
// }

// .slide.slide--editor .slide-choice span.katex-container.katex-container--selected .katex .base{
//   box-shadow: -2px 0px 0px 0px $equationSelectionBlueOpaque, 2px 0px 0px 0px $equationSelectionBlueOpaque;
// }


// .slide.slide--editor .slide-choice.slide-choice--focused span.katex-container *::selection{
//   background: $equationSelectionBlueOpaque !important;
//   box-shadow: -4px 0px 0px 0px $equationSelectionBlueOpaque, 4px 0px 0px 0px $equationSelectionBlueOpaque;
// }



// .slide.slide--editor .slide-body span.katex-container.katex-container--selected .katex .base{
//   box-shadow: -4px 0px 0px 0px $equationSelectionBlueOpaque, 4px 0px 0px 0px $equationSelectionBlueOpaque;
// }



// // this hides the selection so looks hover, but is then unclear that is selection
// // .slide.slide--editor .slide-body.slide-body--focused span.katex-container:hover *::selection{
// //   background: $invisible !important;
// //   box-shadow: none;
// //   // box-shadow: -4px 0px 0px 0px $equationSelectionBlueOpaque, 4px 0px 0px 0px $equationSelectionBlueOpaque;
// // }


// .slide.slide--editor span.katex-container.ProseMirror-selectednode .katex .base{
//   background: $hoverEquationBG;
//   //border: 4px solid $invisible;
//   //display: border-box;
//   cursor: pointer;
//   border-radius: 5px;
//   box-shadow: 0px 0px 0px 3px $hoverEquationBG;
// 


// .slide.slide--editor .ProseMirror-selectednode .katex,
// .slide.slide--editor .ProseMirror-selectednode .katex:hover{
//   //background: transparentize($blue, 0.9);
//   // background: $selectionBlue;
//   background: rgba(0,116,255,0.15);
//   border: 4px solid $invisible;
//   //background: rgba(0,116,255,0.075);
//   display: border-box;
//   //border-bottom: 4px solid rgba(0,116,255,0.75);
// }

// .slide.slide--editor .ProseMirror-selectednode .katex:hover .base{
//   //background: rgba(0,116,255,0.15);
//   background: $equationSelectionBlueOpaque;
// }

// .slide-body.slide-body--focused span.katex-container::selection,
// .slide-body.slide-body--focused span.katex-container *::selection,
// .slide-choice.slide-choice--focused span.katex-container::selection,
// .slide-choice.slide-choice--focused span.katex-container *::selection{
//   background: $invisible !important;  
//   //background: $tempr !important;
//   // put back in , temp for dev
// }

// // .slide.slide--editor span.katex-container:before {
// //   //content: '\200b' !important; // unicode zero width space character
// //   //background: $selectionBlue !important;
// //   //content: 'a' !important; // unicode zero width space character
// // }

// // .slide.slide--editor span.katex-container.katex-container--selected .katex{
// //   //background: $tempr;
// // }


// // fix hover



// .slide-body span.katex-container .katex{
//   opacity: 0.2;
// }

  



//
// FRIENDLY FRACTIONS
//

// .friendlyFractionOuterContainer{
//   display: inline-block;
//   margin: 0;
//   position: relative;
//   text-align: center;
//   white-space: nowrap; // breaks ghost horizontal stuff
// }


// .friendlyFractionInnerContainer{
//   border: solid 1px transparent;
//   display: inline-block;
//   padding: 0 2px;
//   user-select: none !important;
// }

// .friendlyFractionInnerContainer  * {
//   user-select: none !important;
// }

// .friendlyFractionOuterContainer.ProseMirror-selectednode .friendlyFractionInnerContainer{
//   background: $equationSelectionBlueOpaque;
// }

// .friendlyFractionInnerContainer-imageSpacer{
//   bottom: 0;
//   left: 0;
//   opacity: 0;
//   position: absolute;
//   right: 0;
//   top: 0;
// }

// .katexInnerContainer-content{
//   pointer-events: none;
// }

// .katexInnerContainer-content .katex {
//   font-display: block;
// }

.slide.slide--editor .friendlyFractionOuterContainer{
  //cursor: pointer;
}

// .slide.slide--editor .friendlyFractionOuterContainer:hover:not(.ProseMirror-selectednode){
//   background: $hoverEquationBG;
//   box-shadow: 0px 0px 0px 3px $hoverEquationBG;
// }

// .slide.slide--editor .friendlyFractionOuterContainer:active:not(.ProseMirror-selectednode){
//   background: darken($hoverEquationBG, 5%);
//   box-shadow: 0px 0px 0px 3px darken($hoverEquationBG, 5%);
// }


// .katex-container{
//   white-space: nowrap; //prevents wrapping
// }



.friendlyFraction {
  // background: red;
  display: inline-block;
  position: relative;
  vertical-align: middle; 
  letter-spacing: 0.001em;
  text-align: center;

  // background: $tempr;
}

.friendlyFraction-denominator,
.friendlyFraction-numerator{
  display: block;
  min-width: 0.9em;
  padding: 0.03em; 

  min-height: 1em;
}

.friendlyFraction-denominator--empty,
.friendlyFraction-numerator--empty{
  background: $blue-extraLight;
}


.friendlyFraction-denominator{
  border-top: 0.07em solid black;
}

// .friendlyFraction > div { 
//   display: block; 
//   padding: 0.03em; 
// }

// .friendlyFraction .fup { 
//   // background: $tempr;
// }
// .friendlyFraction .fdn::after,
// .friendlyFraction .fdn::before,
// .friendlyFraction .fup::before,
// .friendlyFraction .fup::after{
//   content: '\200A';
// }

// .friendlyFraction .fup,
// .friendlyFraction .fdn { 
//   // min-width: 60px; 
//   min-width: 0.9em;
// }

// .friendlyFraction .fup:empty,
// .friendlyFraction .fdn:empty{
//   background: $blue-extraLight;
// }

// .friendly-fraction-text-placeholder{
//   color: $textInputPlaceholder;
//   // user-select: none;
// }



// .friendlyFraction span.fdn {
//  border-top: 0.07em solid black;
// }

// .friendlyFraction span.bar {
//  display: none;
// }
