body{
	margin: 0;
	background: rgb(250,250,255);
	overflow: hidden;
}

// TEST CURSOR
.nowPlaying,
.nowPlaying *,
.nowPlaying-perfectScoreCelebrationContainer,
.nowPlayingContainer * {
	cursor: url('image/cursor.png') 18 18, auto !important;
	cursor: -webkit-image-set(
		url('image/cursor.png') 1x,
		url('image/cursor@2x.png') 2x
	) 18 18, auto !important;
}


@import "NormalizeV8.scss";
@import "Colors.scss";
@import "Type.scss";

@import "homePage/HomePage.scss";
@import "nowPlaying/NowPlaying.scss";
@import "slide/Slide.scss";
@import "ContentBlocked.scss";
