.nowPlaying-popBarContainer{
	position: fixed;
	width: 310px;
	height: 90px;
	left: calc(50vw - (310px / 2));
	z-index: 999;
	transform-origin: top center;
}

.nowPlaying-popBarContainer{
	bottom: 5vh;
}

.nowPlaying-popBar{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	background: $white;
	border: 1px solid lighten($lineDark, 2%);
	box-shadow: 0px 4px 8px 0px rgba(0,0,20,0.08);

	border-radius: 7px;

	box-sizing: border-box;
	padding: 8px;

	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;
}

.nowPlaying-popBar--survey{
	height: 75px;
	margin: auto;
}

.nowPlaying-popBar-text{
	text-align: center;
	position: relative;
	z-index: 5;
	box-sizing: border-box;
	padding-left: 8px;
	padding-right: 8px;
}

.nowPlaying-popBar-text-primary{
	margin-bottom: 2px;
	font-size: 18px;
	font-weight: $lato-heavy;
}

.nowPlaying-popBar-text-secondary{
	font-size: 15.5px;
	font-weight: $lato-medium;
	
	max-height: 38px;

	display: -webkit-box;
	-webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

	text-overflow: clip;
	white-space: normal;
	overflow: hidden; 

	word-wrap: wrap;

	opacity: 0.8;
}


.nowPlaying-popBar-bg{
	position: absolute;
	top: 8px;
	left: 8px;
	right: 8px;
	bottom: 8px;

	background: $tempr;

	border-radius: 5px;
}

//
// STATE STYLING
//

.nowPlaying-popBar--correct .nowPlaying-popBar-text{
	// color: darken($color-o85, 10%);
	//color: #00B271;
	color: #00A464;
	// color: #00AD66;
	// color: $white;
}

.nowPlaying-popBar--correct .nowPlaying-popBar-bg{
	background: transparentize($color-o85, 0.95);
	background: $color-o85;
}


.nowPlaying-popBar--incorrect .nowPlaying-popBar-text{
	color: darken($color-u60, 10%);
}

.nowPlaying-popBar--incorrect .nowPlaying-popBar-bg{
	background: transparentize($color-u60, 0.95);
	background: $color-u60;
}


.nowPlaying-popBar--survey .nowPlaying-popBar-text{
	color: darken($blue-bold, 2%);
}

.nowPlaying-popBar--survey .nowPlaying-popBar-bg{
	background: transparentize($blue-bold, 0.95);
}


// 
// PROGRESS BAR

.nowPlaying-popBar-progressBar{
	position: absolute;
	left: 20px;
	right: 20px;
	height: 5px;
	border-radius: 2.5px;
	overflow: hidden;
	background: $gray-14;
}

.nowPlaying-popBar-progressBar-bar{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	width: 80%;
	background: $gray-11;
	z-index: 5;
}