.slide .slide-choices{
	margin-top: 25px;
	display: flex;
}

.slide--template--bodyLeft .slide-choices,
.fontSizeChoicesArray--template--bodyLeft .slide-choices{
  width: 960px;
  flex-direction: column;
  justify-content: flex-end;
}

.slide.slide--template--bodyLeftMediaRight .slide-choices,
.fontSizeChoicesArray--template--bodyLeftMediaRight .slide-choices{
  width: 550px;
  flex-direction: column;
  justify-content: flex-end;
}

.slide.slide--template--bodyRightMediaLeft .slide-choices,
.fontSizeChoicesArray--template--bodyRightMediaLeft .slide-choices{
  margin-left: 610px;
  width: 550px;
  flex-direction: column;
  justify-content: flex-end;
}

.slide.slide--template--bodyCenter .slide-choices,
.fontSizeChoicesArray--template--bodyCenter .slide-choices{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.slide.slide--template--bodyCenterChoicesMedia .slide-choices,
.slide.slide--template--bodyLeftMediaRightChoicesMedia .slide-choices{
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  //justify-content: space-between;
  justify-content: space-around;

  caret-color: $invisible; // hide caret as possible to tab into
}


.slide.slide--template--bodyCenterMediaCenter .slide-choices,
.fontSizeChoicesArray--template--bodyCenterMediaCenter .slide-choices{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.fontSizeChoicesArray--template--bodyCenter .slide-choices,
.fontSizeChoicesArray--template--bodyCenterMediaCenter .slide-choices{
  width: 1160px;
}


