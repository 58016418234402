//--- COLOURS ---// 

	// CONVERT HSB COLOURS TO HSL FOR WEB USE

	@function hsb($h-hsb, $s-hsb, $b-hsb, $a: 1) {
	  @if $b-hsb == 0 {
	    @return hsla(0, 0, 0, $a)
	  } @else {
	    $l-hsl: ($b-hsb/2) * (2 - ($s-hsb/100));
	    $s-hsl: ($b-hsb * $s-hsb) / if($l-hsl < 50, $l-hsl * 2, 200 - $l-hsl * 2);
	    @return hsla($h-hsb, $s-hsl, $l-hsl, $a);
	  }
	}

// PRIMARY COLORS

// $blue: 				hsb(203, 96, 92);
$blue: 						rgb(22,123,240); // plickers blue
$blue-light: 			rgb(65,153,255); // light variant
$blue-lighter: 			rgb(219,234,254); // lightest variant
$blue-extraLight: lighten($blue, 45%);
$blue-dark: 		hsb(203, 90, 81);
$blue-bold: 			rgb(0,114,255); // 2020 bolder blue


// $blue: 				hsb(212, 69, 100);
// $blue-extraLight: rgb(237, 245, 255);
// $blue-light: 	hsb(207, 60, 100);



$green: 			hsb(162, 76, 79);
$green-dark: 		hsb(162, 81, 69);
// $green-dark: 	hsb(162, 76, 71);
// $green-light: 	hsb(162, 62, 85);

$red: 				hsb(360, 55, 94);
$red-dark:			hsb(360, 59, 93);
// $red-light: 		hsb(356, 52, 100);

$color-itemEditor-toolbarbg:  	rgb(255,255,255);
$BG-itemEditor-questionList: rgb(244,244,252);

$colorBG-fullScreen-controls: rgba(26,28,34,0.85);

$color-sectionHome-dividerGray: rgb(220,220,227);


$red--notification: rgb(253,61,48);

// NEW COLORS
$textBlackAbsolute: rgb(0,0,0);
$textBlackDark: rgb(10,	10,	24);
$textBlack: rgb(33,	33,	51);
$textDark: 	rgb(65,	65,	78);
$textMid: 	rgb(97,	97,	114);
$textLight: rgb(140, 139, 160);
$textBlue: $blue;
$textInputPlaceholder: rgb(204, 204, 218);

// $lineLight: 	rgb(242,242,250);
$lineLight: 	rgb(235,235,240);
$lineMid: 		rgb(235,235,240);
$lineMidDark: rgb(206,206,213);
$lineDark: 		rgb(216,216,219);

$color-navBar-BG: rgb(242,242,249);
$border-navBar: 1px solid rgb(225,225,235);
$shadow-navBar: 0px 1px 2px 0px rgba(0,0,10,0.04);

$iconGray: rgb(155,155,163);

$color-pageBG: rgb(245,244,251);
// $color-pageCenterBorder: rgb(240,241,245);
$color-pageCenterBorder: rgb(225,225,232);

$color-cellBG-zebra: rgb(251,251,254);

$cloudBGLightBlue:  rgb(241,247,255);

$color-pageLoaderElement: rgb(244,244,255);

// GREYS
// gray-1 is darkest, gray-16 is lightest

$gray-1: 	rgb(22,	22,	29);
$gray-2: 	rgb(51,	52,	54);
$gray-3: 	rgb(70,	70,	86);
$gray-4: 	rgb(80,	81,	94);
$gray-5: 	rgb(97,	97,	114);
$gray-6: 	rgb(110,110,130);
$gray-7: 	rgb(124,123,142);
$gray-8: 	rgb(159,159,168);
$gray-9: 	rgb(170,171,178);
$gray-10: 	rgb(183,183,188);
$gray-11: 	rgb(196,195,205);
$gray-1125: rgb(204,204,216);
$gray-115: 	rgb(206,206,213);
$gray-12: 	rgb(210,214,220);
$gray-1225:	rgb(216,216,219);
$gray-125: 	rgb(225,225,230);
$gray-128: 	rgb(230,232,235);
$gray-13: 	rgb(240,241,244);
$gray-14: 	rgb(246,247,248);
$gray-15:  	rgb(250,250,253);
$gray-16: 	rgb(253,253,253);

$white: 	rgb(255,255,255);
$black: 	rgb(0,	0,	0);

$invisible: rgba(0,0,0,0);



// CLASS COLORS

$classColor-blue: 	rgb(54,153,249);
$classColor-purple: rgb(181,120,221);
$classColor-red: 		rgb(243,127,130);
$classColor-orange: rgb(255,170,112);
$classColor-green:	rgb(49,201,157);
$classColor-sky:		rgb(88,199,249);
$classColor-pink:		rgb(247,145,201);
$classColor-teal:		rgb(0,191,205);
$classColor-grey:		rgb(128,129,142);

$classColor-none: 	$classColor-blue;



// TEMPORARY FOR STATIC UI BUILDING
$tempr: rgba(255,0,0,0.25);
$tempg: rgba(0,255,0,0.25);
$tempb: rgba(0,0,255,0.25);

$tempra: rgba(255,0,0,0.05);
$tempga: rgba(0,255,0,0.05);
$tempba: rgba(0,0,255,0.05);


// ASSESSMENT COLORS
$color-o85: rgb(0,207,132); // over 85%
$color-o70: rgb(108,213,133); // over 70% and equal
$color-u70: rgb(242,142,92); // under 70%
$color-u60: rgb(243,107,100); // under 60%

// CLASS MARKER COLORS
.classMarker.color-blue 			{ background: $classColor-blue; }
.classMarker.color-purple 		{ background: $classColor-purple; }
.classMarker.color-red 				{ background: $classColor-red; }
.classMarker.color-orange 		{ background: $classColor-orange; }
.classMarker.color-green 			{ background: $classColor-green; }
.classMarker.color-sky 				{ background: $classColor-sky; }
.classMarker.color-pink 			{ background: $classColor-pink; }
.classMarker.color-teal 			{ background: $classColor-teal; }
.classMarker.color-grey 			{ background: $classColor-grey; }

.classMarker.color-none 			{ background: $classColor-none; }

// HIGHLIGHT COLORS FOR RICH TEXt

$highlight--1-textColor: #EF3136;
$highlight--1-bgColor: #FEEBEB;
// $highlight--1-bgColor: transparentize(#EF3136, 0.9);

$highlight--2-textColor: #DF6513;
$highlight--2-bgColor: #FFEEE2;
// $highlight--2-bgColor: transparentize(#DF6513, 0.9);

$highlight--3-textColor: #009D6F;
$highlight--3-bgColor: #D6F4EB;
// $highlight--3-bgColor: transparentize(#009D6F, 0.9);

$highlight--4-textColor: #0C77DE;
$highlight--4-bgColor: #EBF4FE;
// $highlight--4-bgColor: transparentize(#0C77DE, 0.9);

$highlight--5-textColor: #9935DB;
$highlight--5-bgColor: #F0E4F8;
// $highlight--5-bgColor: transparentize(#9935DB, 0.9);

$highlight--6-textColor: #AE7E16;
$highlight--6-bgColor: #FFF2D1;
// $highlight--5-bgColor: transparentize(#9935DB, 0.9);

$highlight--7-textColor: #F461B2;
$highlight--7-bgColor: #FDE1F0;
// $highlight--5-bgColor: transparentize(#9935DB, 0.9);

$highlight--8-textColor: #ACACB5;
$highlight--8-bgColor: $white;
// $highlight--5-bgColor: transparentize(#9935DB, 0.9);
