$homePageBGColor: rgb(252,252,255);

.homePageContainer{
	background: $homePageBGColor;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	display: flex;

	justify-content: center;
	align-itens: center;
}

.homePage{
	width: 85vw;
	max-width: 1200px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	position: relative;
}

.homePage--preAuthorization{
	box-sizing: border-box;
	padding-top: 50px;
}

//

@import 'homePage-topBar';
@import 'homePage-greeting';

/// SECTION GRID

.homePage-sectionGrid{
	display: flex;
	width: 100%;
	flex-wrap: wrap;

	margin-top: 30px;

	margin-left: -10px; // refactor, from margin on section card

}

@import 'homePage-sectionCard';



//
// EDIT CLASSES BUTTON

button.homePage-editSectionBtn{
	position: absolute;
	bottom: 20px;
	left: 0px;

	padding: none;
	border: none;
	box-shadow: none;
	outline: none;
	background: none;

	font-size: 14px;

	// background: $tempr;

	padding-left: 12px;
	padding-right: 12px;
	padding-top: 7px;
	padding-bottom: 7px;

	margin-left: -12px;

	border-radius: 4px;

	cursor: pointer;

	user-select: none;
}

button.homePage-editSectionBtn--inactive{
	font-weight: $lato-semibold;
	color: $textDark;
	opacity: 0.4;
}

button.homePage-editSectionBtn--inactive:hover{
	opacity: 0.9;
	background: rgba(0,0,20,0.04);
}



button.homePage-editSectionBtn--active{
	font-weight: $lato-heavy;
	color: $blue-bold;
}

button.homePage-editSectionBtn--active:hover{
	color: darken($blue-bold, 5%);
	background: transparentize($blue-bold, 0.95);
}


div.homePage-cookiPolicyCenterLinks {
  position: absolute;
  bottom: 20px;
  right: 1px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

button.homePage-policyCenterButton {
  padding: none;
  border: none;
  box-shadow: none;
  outline: none;
  background: none;

  font-size: 14px;

  padding-left: 12px;
  padding-right: 12px;
  padding-top: 7px;
  padding-bottom: 7px;

  border-radius: 4px;

  cursor: pointer;

  user-select: none;

  font-weight: $lato-semibold;
  color: $textDark;
  opacity: 0.4;
}

button.homePage-policyCenterButton:hover{
  opacity: 0.9;
  background: rgba(0,0,20,0.04);
}


// ///
// /// JOIN CLASS BUTTON
// /// 


// .homePage-joinClassBtnContainer{
// 	display: flex;
// 	align-items: center;	
// 	justify-content: center;

// 	box-sizing: border-box;

// 	width: 100px;
// 	height: 50px; 
	
// 	border-radius: 5px;

// 	position: relative;

// 	// background: $tempr;

// 	margin-top: 30px;
// }

// button.homePage-joinClassBtn{
// 	width: 36px;
// 	height: 36px;
// 	border-radius: 18px;
// 	border: none;
// 	outline: none;
// 	box-shadow: none;

// 	background: $gray-5;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	cursor: pointer;

// 	transition: all 150ms cubic-bezier(.2, .3, .25, .9);

// 	opacity: 0.2;
// }

// button.homePage-joinClassBtn svg{
// 	width: 16px;
// 	height: 16px;
// 	fill: $homePageBGColor;
// }

// .homePage-joinClassBtnContainer:hover button.homePage-joinClassBtn{
// 	opacity: 0.5;
// 	transition: all 150ms cubic-bezier(.2, .3, .25, .9);
// }

// .homePage-joinClassBtnContainer button.homePage-joinClassBtn:hover{
// 	transform: scale(1.1);
// 	opacity: 0.8;
// 	transition: all 150ms cubic-bezier(.2, .3, .25, .9);
// }

// .homePage-joinClassBtnContainer button.homePage-joinClassBtn:hover svg{
// 	transform: scale(1.1);
// 	transition: all 150ms cubic-bezier(.2, .3, .25, .9);	
// }

// .homePage-joinClassBtnContainer button.homePage-joinClassBtn:active{
// 	opacity: 1;
// }


// .homePage-joinClassBtnContainer-label{
// 	position: absolute;
// 	bottom: -12px;
// 	left: 0px;
// 	right: 0px;
// 	font-size: 13px;
// 	font-weight: $lato-bold;
// 	color: lighten($textMid, 5%);
// 	text-align: center;

// 	cursor: default;
// 	user-select: none;

// 	opacity: 0;
// 	transform: translateY(-5px);
// 	transition: all 100ms cubic-bezier(.2, .3, .25, .9);
// }

// button.homePage-joinClassBtn:hover ~
// .homePage-joinClassBtnContainer-label{
// 	opacity: 1;
// 	transform: translateY(0px);

// 	transition: all 250ms cubic-bezier(.2, .3, .25, .9);

// 	transition-delay: 500ms;

// }
